.content-row-products.content-row {
  padding: 0;
}

.content-row-products {
  background-color: $colorAction;

  .product:hover {
    .front-side {
      top: 100%;
    }

    .back-side {
      top:0;
    }
  }
}

.content-row-products .product-rows {
  max-width: 1170px;
  margin: 0 auto;
}

.product-rows .row {
  margin-right: 0;
  margin-left: 0;
}

.content-row-products .product {
  position: relative;
  overflow: hidden;
  padding:0;
  max-height: 350px;
  min-height: 350px;
  text-align: center;
  cursor: pointer;
  border-right:1px solid $colorNeutral;
}
.content-row-products .product:last-child {
  border-right:none;
}

.content-row-products .product-name {
  font-family: $fontHeadings;
  color: #16262c;
  font-weight: 700;
  margin:0 0 30px 0;
  text-transform:uppercase;
}


.content-row-products .front-side,
.content-row-products .back-side {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: top .3s cubic-bezier(0.65, 0.05, 0.36, 1);
}


/* Products View Front-Side */

.content-row-products .front-side {
  top: 0;
}

.content-row-products .front-side .btn {
  border-color:$colorPrimary;
  color:$colorPrimary;
}

/* Products View Back-Side */
.content-row-products .back-side {
  top: -100%;
  background-color: $colorPrimary;
  color:$colorNeutral;
}

.content-row-products .back-side .product-name {
  color:$colorNeutral;
}

.content-row-products .back-side .btn.btn {
  background-color: $colorAction;
  color:$colorNeutral;
}

.content-row-products .back-side a {
  color:$colorNeutral;
}

.content-row-products .product-logo {
  img {
    width: 170px;
    height:170px;
  }
}


@media screen and (max-width:767px) {
  .content-row-products .product {
    border-right:none;
    border-bottom:1px solid $colorPrimary;
  }

  .content-row-products .product:last-child {
    border-bottom:none;
  }
}

@media (hover: none) {
  .content-row-products .front-side .product-name {
    padding:15px 30px;
    border:1px solid $colorNeutral;
    border-radius:30px;

    font-size:1.8rem;
  }

}