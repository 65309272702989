.site-header {
  position:relative;
  z-index:999;
  top:30px;
}


.site-header-container {
  @extend .container;
}

.site-header .site-identity {
  @include make-xs-column(6);
  @include make-sm-column(3);
}

.site-header .site-nav {
  @include make-xs-column(3);
  @include make-sm-column(2);

  @include make-xs-column-offset(3);
  @include make-sm-column-offset(7);
}
