
/***********************************************************************************************************************
*   Margin Utilities
************************************************************************************************************************/

// stylelint-disable declaration-no-important

$spacingSizes: (
  0: 0px,
  1: 15px,
  2: 30px,
  3: 45px,
  4: 60px
);



// Margin and Padding

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacingSizes {
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}


.no-margin {
  margin:0!important;
}


/***********************************************************************************************************************
*   Padding Utilities
************************************************************************************************************************/
.no-padding {
  padding:0!important;
}