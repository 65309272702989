//Import general slider styles
@import 'generalSlider';

.slider-wrapper.slider-type-panoramic {
    .slider-item {
        color: $colorNeutral;
        z-index: 2;
    }

    .slider-item.slider-dummy {
        z-index: 1;
    }

    .slider-item-content {
        width: 100%;
        padding: 60px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1170px;
        margin: 0 auto;
    }

    .slider-item-content .container {

    }


    /* Fix background */
    .slider-layers .slider-layer:first-child {
        position: fixed !important;
        left: 0;
        right: 0;
    }


    @media screen and (max-width: 600px) {
        .slider-item-content {
            padding: 15px;
        }
    }
}