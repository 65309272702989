@use "sass:math";

@mixin tabsRowSize($tabsPerRow:6, $height:16.3rem, $border:true) {

  $tabWidth:math.div(100, $tabsPerRow)+unquote("%");

  @if($border) {
    $tabWidth:calc(#{math.div(100, $tabsPerRow)}% - 1px);

    .tab-bar-item:nth-child(#{$tabsPerRow}n) {
      margin-right: 0;
    }
  }


  .tab-bar .tab-bar-item {
    width:$tabWidth;
    height:$height;
  }

}



.tab-bar {

  display:block;
  margin-top:40px;


  .tab-bar-item {
    display: block;
    position:relative;
    float:left;
    padding:10px;
    margin-right:1px;
    margin-bottom:1px;

    background-color:$colorNeutral;
    color:$colorAction;
    cursor:pointer;

    transition:all ease-in 0.2s;
  }

  .tab-bar-item:nth-child(6n){
    margin-right:0;
  }

  .tab-bar-icon {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-60%);

    font-size:4rem;
    transition:all ease-out 0.2s;

  }

  .tab-bar-item-label {
    position:absolute;
    margin:0;
    left:1rem;
    right:1rem;
    bottom:2rem;

    text-align:center;
    text-transform:uppercase;
    transition:all ease-out 0.2s;

  }


  //Active Tab
  .tab-bar-item.active {
    background-color:$colorAction;
    color:$colorNeutral;

    transition:all ease-out 0.2s;
  }

  .tab-bar-item.active .tab-bar-icon,
  .tab-bar-item.active .tab-bar-item-label {
    transition:all ease-out 0.2s;
  }

  //Item Counter
  .tab-bar-item-count {
    display:block;
    position:absolute;
    top:0.5rem;
    left:0.5rem;

    width:2.5rem;
    height:2.5rem;

    line-height:2.5rem;
    text-align:center;

    background-color:$colorWarning;
    color:$colorNeutral;

    border-radius:50%;
  }


  &:after{
    content:'';
    display:table;
    clear:both;
  }


  //Hide if only one category is available
  &.category-count-1 {
    display:none;
  }
}

.tab-bar ~ .offer-container .product-offer:first-child {
  margin-top:0;
}

.tab-bar ~ .offer-container {
  animation: 'slideInRight' 0.8s;
}



@include tabsRowSize(6, 16.3rem, true);


@media screen and (max-width:990px) and (min-width:768px) {
  @include tabsRowSize(5, 15rem, true);
}


@media screen and (min-width:600px) and (max-width:767px) {
  @include tabsRowSize(4, 15rem, true);
}

@media screen and (min-width:375px) and (max-width:599px) {
  @include tabsRowSize(3, 12rem, true);

  .tab-bar {
    .tab-bar-item-label {
      position:absolute;
      left:0.25rem;
      right:0.25rem;
      bottom:0.75rem;

      font-size:1.4rem;
    }

    .tab-bar-icon {
      font-size:3rem;

    }
  }
}

@media screen and (max-width:374px) {
  @include tabsRowSize(2, 14rem, true);

  .tab-bar .tab-bar-icon {
    font-size:3rem;
  }
}