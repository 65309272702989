.site-nav-control {
    z-index:999;
}

.nav-active .site-nav-control {
    position:fixed;
}

.nav-control-icon {
    position: relative;
    display: block;
    margin: auto auto;
    padding: 16px 0;
    width: 59px;
    height: 59px;
    cursor: pointer;
    z-index: 100;

    border-radius: 50%;
}

.nav-control-icon span {
    position: absolute;
    display: block;
    width: 29px;
    height: 2px;
    margin: 0 15px;
    background-color: $colorPrimary;
    transition: all ease-in-out 0.3s;
}

.nav-control-icon span:nth-child(1) {
    top: 16px;
}

.nav-control-icon span:nth-child(2) {
    top: 27px;
}

.nav-control-icon span:nth-child(3) {
    top: 38px;
}

.nav-active {
    .nav-control-icon {
        transition: all ease-in-out 0.3s;
    }

    .nav-control-icon span {
        width: 38px;
        margin: 0 10px;
        transition: all ease-in-out 0.3s;
    }

    .nav-control-icon span:nth-child(1) {
        top: 28px;
        transform: rotate(-45deg);
    }

    .nav-control-icon span:nth-child(2) {
        opacity: 0;
        width: 0;
    }

    .nav-control-icon span:nth-child(3) {
        top: 28px;
        transform: rotate(45deg);
    }
}
