/*
Color Vars
 */

$colorPrimary:#012744;
$colorAction:#339999;
$colorError:#972400;
$colorWarning: #e35301;
$colorNeutral:#fff;
$colorContentBg1: #3e5f78;
$colorContentBg2: #2a4960;
$colorContentBg3: #2a3e55;
$colorBackground: #152c3d;
$colorSolemn: #012744;

$currencyCode: 'NOK';
$countryCode: 'NO'; //NO, FI, DA, SE or other ISO code
$phoneAreaCode: '+47';
$numYearsTxt: 'ANTAL ÅR';


$textShadow: 0 0 30px transparentize($colorPrimary, 0.5),0 0 15px transparentize($colorPrimary, 0.7),0 0 10px transparentize($colorPrimary, 0.7);
$textShadowHeadings: 0 0 80px transparentize($colorPrimary, 0.2),0 0 50px transparentize($colorPrimary, 0.5),0 0 30px transparentize($colorPrimary, 0.7);

$inputHeight:5.5rem;