
/***********************************************************************************************************************
*   Custom Content - Left or Right Side
************************************************************************************************************************/

.left-custom-content,
.left-custom-content + .step-content,
.right-custom-content,
.right-custom-content + .step-content {
    width: 50%;
}

.left-custom-content,
.right-custom-content + .step-content {
    float: left;
}

.left-custom-content + .step-content,
.right-custom-content {
    float: right;
}

@media screen and (max-width:767px) {
    .left-custom-content,
    .right-custom-content {
        padding:0;
        display:none;
    }

    .left-custom-content,
    .left-custom-content + .step-content,
    .right-custom-content,
    .right-custom-content + .step-content {
        width: 100%;
    }
}



/***********************************************************************************************************************
*   Custom Content - Top
************************************************************************************************************************/

.top-custom-content,
.top-custom-content + .step-content {
    width:100%;
}


.top-custom-content{
    float: none;
}

@media screen and (max-width:767px) {
    .top-custom-content,
    .top-custom-content + .step-content {
        width: 100%;
    }
}
