.page-background {
    display: block;
    position: fixed;
    top: -10px;
    right: -10px;
    left: -10px;
    bottom:-10px;
    z-index: -1;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;

    //Trigger GPU acceleration with 3D transform:
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    //Fix potential flickering in Webkit:
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;

    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
}



