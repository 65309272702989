@import "DevBar";
@import "ColorClasses";
@import "ResponsiveTypes";


.body {
    width: 100%;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.scroll-down.btn-link {
    display: inline-block;
    margin: 0 auto;
}

.page-heading {
    margin:160px 0 60px 0;
}

.has-slider .page-heading {
    margin:0;
}