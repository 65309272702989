/***********************************************************************************************************************
*                             Styles for Creditti Home form and pages
*
*     This stylesheet handles all the overrides done on Creditti Home product, and should ONLY contain styles related
*     to this specific product.
*
*                                  TABLE OF CONTENTS
*
*     1.    Form
*
************************************************************************************************************************/

.creditti-home .page-wrapper,
.creditti-factor .page-wrapper {
  margin:60px 0 0;
}

.page-heading h3 {
  text-transform:none;
}

.creditti-home .creditti-home-icon,
.creditti-factor .creditti-factor-icon {
  width:240px;
  height:240px;

  margin:0 auto;
}

.creditti-home .creditti-home-icon {
  padding:30px;

  border:5px solid $colorAction;
  border-radius:50%;
}



/***********************************************************************************************************************
*   1.  Form
************************************************************************************************************************/
/*

.product-creditti-home,
.product-creditti-factor {
  label {
    text-shadow: none;
    color: $colorPrimary;
    text-transform:none;
    font-weight:500;
  }

  .field-description,
  .form-description-content,
  label.checkbox ~ p {
    text-shadow: none;
    color: $colorPrimary;
    text-transform:none;
  }

  .section-heading {
    text-shadow: none;
    color: $colorPrimary;
    font-size:2.2rem;
  }
}

.product-creditti-factor .product-form-wrapper,
.product-creditti-home .product-form-wrapper {
  @extend .container;
  max-width:95%;
  padding:0;
  margin:0 auto 60px auto;
  border-radius: 20px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.75);
  background-color:#fff;

  overflow:hidden; //hides the progress bar when corners are rounded severely
}

.product-creditti-factor .product-form .step,
.product-creditti-home .product-form .step {
  margin:0;
  padding:40px 40px 60px 40px;
}

.product-creditti-factor .sectionWrapper:first-child .section,
.product-creditti-home .sectionWrapper:first-child .section {
  padding-top:0;
}



@media screen and (max-width:767px) {
  .product-creditti-factor .product-form .step,
  .product-creditti-home .product-form .step {
    padding:20px;
  }
}



.product-creditti-factor .progress-wrapper,
.product-creditti-home .progress-wrapper {
  padding:0;
}

*/




/***********************************************************************************************************************
*   Result Page Offers
************************************************************************************************************************/
.creditti-home__resultat .resultpage-offers {
  @extend .row;
}

.creditti-home__resultat .product-offer {
  border-radius:10px;
  box-shadow: 5px 15px 30px 0 rgba(0,0,0,.75);
}



/***********************************************************************************************************************
*   2.  Inline Offer Form
************************************************************************************************************************/

.product-creditti-home .inline-offer {
  margin-bottom:40px;
  border-radius:12px;

  .section-heading,
  .field-description,
  .form-description-content,
  .section-heading,
  label,
  label.checkbox ~ p {
    color:$colorNeutral;
  }

  .form-description {
    margin-top:0;
  }

  .form-description-label {
    text-align:center;
    font-size:2.4rem;
    font-weight:bold;
  }

  &-hidden {
    padding:20px 40px;
  }

  &-hidden .control-show a {
    display:block;
    font-size:0!important;
    width:100%;
    height:100%;
  }
}