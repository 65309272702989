@import "Inputs";
@import "InputsSpecial";




/***********************************************************************************************************************
*   0.  Form Elements
************************************************************************************************************************/

.section {
  margin: 2em 0em;
}

.section .wrapper:after {
  display:table;
  content:"";
  clear:both;
}

.section-description {
  font-weight:bold;
  margin:0 0 40px 0;
}

.sectionNav {
  margin-top: 20px;
}

@media screen and (max-width:800px) {
  .sectionNav {
    margin-top:2em;
  }
}




/***********************************************************************************************************************
*   1.  Form Height Animation
************************************************************************************************************************/

.formHeightWrapper {
  transition: height 1s;
  overflow:hidden;
}




/***********************************************************************************************************************
*   2.  Form Step Switch
************************************************************************************************************************/


.stepped, .extended, .mixed, .form-window {
  position: relative;
  overflow: hidden;
}

.form-input {
  position:relative;
  width: 100%;
}

.form-input input {
  width:100%;
}

.add-debt {
  border: none;

}





/************************************************************************************
*   2.1  Switch between steps
*************************************************************************************/

.stepped, .mixed, .form-window {
  .step {
    width: 100%;
    right: 105%;
    float: left;
    position: absolute;
    transition: right 0.5s ease;
  }

  .step.active {
    position: relative;
    right: 0;
    margin-bottom:60px; //provides space for dropdowns at bottom of forms
  }

  .step.active ~ .step {
    right: -105%;
    transition: right 0.5s ease;
  }
}





/************************************************************************************
*   Empty Steps
*************************************************************************************/

.form-window .empty-step {
  height:0;
}





/************************************************************************************
*   2.2  Extended steps
*************************************************************************************/

.extend {
  visibility: hidden;
  width: 100%;
  height: 0;
  transition: height 0.5s ease-in-out;
}

.extend.active {
  height: auto;
  visibility: visible;
  transition: height 0.5s ease-in-out;
}




/***********************************************************************************************************************
*   3. Form Navigation
************************************************************************************************************************/
.sectionNav:after {
  display:table;
  content:"";
  clear:both;
}




/************************************************************************************
*   4.  Newsletter Form
*************************************************************************************/

.content-block-form .form-window .step {
  margin:0;
}

.content-block-form .form-window .sectionWrapper:first-child .section {
  padding-top:0;
}