.dev-bar {
    z-index: 99999;
    position: fixed;

    width: 100%;
    height:30px;
    top:0;

    background-color:$colorAction;
    color:$colorPrimary;

    text-align: center;
    font-size:1.4rem;
    line-height:30px;
}

body.is-dev{
    padding-top:30px;
}