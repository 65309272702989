/***********************************************************************************************************************
*   ContentRows
*
* Provides a flexible layout element to render content on a page.
************************************************************************************************************************/

.content-row {
  position: relative;
  padding: 60px 0px;
  color: $colorPrimary;
}

.content-block {
  position:relative;
}

.block-heading {
  margin-bottom:40px;
}

.block-cta {
  margin:20px;
  text-align:center;
}

.block-cta .btn {
  width:auto;
}



/***********************************************************************************************************************
*   2.  Content Block Super
************************************************************************************************************************/
.content-block-super {


}


@media screen and (max-width:767px){
  .content-block-super .content-col.col-sm-4,
  .content-block-super .content-col.col-sm-6{
    margin-bottom:40px;
  }

  .content-block-super .content-col.col-sm-4:last-child,
  .content-block-super .content-col.col-sm-6:last-child {
    margin-bottom:0;
  }
}





/***********************************************************************************************************************
*   3.  Products Row
************************************************************************************************************************/

/* Products View Front-Side */

.products-view .front-side {
}

.products-view .back-side {
  background-color:$colorSolemn;
}

.products-view .front-side .btn {
  display:none;
}

.products-view .front-side .product-excerpt {
  display:none;
}

.products-view .product-excerpt {
  margin-bottom:20px;
}

/* Products View Back-Side */
.products-view .front-side,
.products-view .back-side,
.products-view .product-name {
  color:$colorNeutral;
}

.products-view .product-logo {
  margin-bottom:40px;
}


.products-view .product-logo {

}


@media screen and (max-width:767px) {

}