/***********************************************************************************************************************
*   6.  Repeater
*
*  The repeater enables a group of fields to be inserted by the user. The repeater has two states, active and
*  inactive. There are two elements rendered for those states. These are always rendered, so it is necessary
*  to use the active/inactive state to determine which element to show and which to hide.
************************************************************************************************************************/


/***********************************************************************************************************************
*  Repeater Items
************************************************************************************************************************/

.repeater-item {
  position:relative;
  color:$colorNeutral;
}


.repeater-item {
  border:1px solid $colorAction;
  border-top:none;
}

.repeater-item:first-child {
  border-top:1px solid $colorAction;
}

.repeater-item:before {
  background-color:$colorAction;
  color:$colorNeutral;
}