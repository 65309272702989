/***********************************************************************************************************************
*   1.  Date Selector
************************************************************************************************************************/

.date-suggestions-container:after {
  content: "";
  display: table;
  clear: both;
}


.date-suggestions {
  float:left;
  position:relative;
  border:1px solid $colorSolemn;
  border-right:none;
}

.date-suggestions:last-child {
  border-right:1px solid $colorSolemn;
}


.date-suggestions ul {
  list-style: none;
  padding:0;
  position: absolute;
  background: $colorNeutral;
  overflow-y: scroll;
  max-height: 165px;
  width:calc(100% + 1px);
  color:$colorPrimary;
  box-shadow: inset 0px -27px 28px -22px rgba(0,0,0,0.2);
  z-index: 9999;

  border-radius: 0;
  border-bottom:1px solid $colorSolemn;

}

.date-suggestions:first-child ul {
  border-left:1px solid $colorSolemn;
}
.date-suggestions:last-child ul {
  border-right:1px solid $colorSolemn;
}

.date-suggestions:last-child ul {
  //border-right:1px solid $colorNeutral;
}

.date-suggestions li {
  padding:6px 0 6px 20px;
}

.date-suggestions li:hover, .date-suggestions li.selected {
  background: $colorPrimary;
  color: $colorNeutral;
}

.date-suggestions li.active {
  background: $colorWarning;
  color: $colorNeutral;
}


$selectImgColor:$colorAction;
$selectImg:"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.41 15.12'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:#{$selectImgColor};stroke-miterlimit:10;stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EAsset 1%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpolyline class='cls-1' points='26.71 0.71 13.71 13.71 0.71 0.71'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";

.date-suggestions input,
.date-suggestions select {
  border:none;
  border-radius:0;
  z-index:999;
  background-image:url($selectImg);
  background-repeat:no-repeat;
  background-position:95% center;
  background-size:1.4em;
}


@media screen and (max-width:800px) {
  .date-suggestions input,
  .date-suggestions select {
    background-size:1.3em;
  }
}





/***********************************************************************************************************************
*   2.  Custom Select
************************************************************************************************************************/

.custom-select {
  margin:15px 0;
}

.currency .td-text-dropdown::after {
  position: absolute;
  top: 0;
  content: $currencyCode;
  font-size: 5rem;
  font-weight: bold;
  padding: 12px;
}

.td-text-dropdown {
  position: relative;
}

.td-text-dropdown input {
  height:9rem;
  padding:0 14rem 0 12px;
  font-size: 5rem;
  font-weight: bold;
  text-align: right;
  line-height:9rem;
}

.td-text-dropdown input#period {
  padding-right:11rem;
}

.td-square {
  position: absolute;
  right: 0%;
  top: 0px;
  height: 100%;
  border: 1px solid transparent;
  border-left: 1px solid $colorSolemn;
  padding:20px;
  z-index:999;
}

.td-arrow-down {
  position: relative;
  top: 38%;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid $colorSolemn;
}

.td-suggestions {
  position: absolute;
  width: 100%;
  max-height: 230px;
  z-index: 9999;
  padding:0;
  list-style-type: none;
  border: 1px solid $colorSolemn;
  background: $colorNeutral;
  font-size: 2.4rem;
  overflow-y:scroll;
}

.td-suggestions li {
  position: relative;
  padding: 6px 7rem 6px 12px;
  color: $colorPrimary;
  font-weight: bold;
  text-align: right;
  cursor:pointer;
}

.td-suggestions span {
  padding-left:12px;
}

.td-suggestions li:hover {
  background: $colorPrimary;
  color: $colorNeutral;
}


.custom-select .measurement-unit {
  position: absolute;
  content: $currencyCode;
  font-size: 2.8rem;
  font-weight: bold;
  padding: 12px;
  right:100%;
  color: $colorSolemn;
}

.period .td-suggestions li:after {
  content: $numYearsTxt;
}
.period .td-suggestions li:last-child:after {

}

.period .td-suggestions li:last-child {

}

.period .td-suggestions li {
  padding-right:11rem;
}


@media screen and (max-width:800px) {
  .product-controls .custom-select input {
    font-size:3rem;
  }

  .custom-select .measurement-unit {
    bottom:0.4em;
  }
}






/***********************************************************************************************************************
*   3.  Email Input
************************************************************************************************************************/

input[type="email"] ~ .email-suggestions {
  position:absolute;
  top:calc(100% - 1px);
  width:100%;

  padding:0;
  margin:0;

  list-style:none;

  background-color:$colorNeutral;
  border:1px solid $colorPrimary;
}

input[type="email"] ~ .email-suggestions li {
  padding:15px 30px;
  cursor:pointer;
}

input[type="email"] ~ .email-suggestions .selected,
input[type="email"] ~ .email-suggestions li:hover {
  color:$colorNeutral;
  background:$colorAction;
}

.email-suggestions .input-value {
  opacity:0.5;
}

@media screen and (max-width:767px) {
  input[type="email"] ~ .email-suggestions li {
    padding:5px 15px;
  }
}




/***********************************************************************************************************************
*   4.  Address Input
************************************************************************************************************************/
//HACK - fixes that the input-suggestions div is visible before input being used,
//and also suggests-visible class does not work on add

[data-field-type="address"] .input-suggestions {
  visibility:hidden;
}

.touched[data-field-type="address"] .input-suggestions {
  position:absolute;
  visibility:visible;
  top:calc(100% - 1px);
  width:100%;
  max-height:340px;
  overflow-x:auto; //Auto hides the scrollbar on devices and browsers that show a scrollbar even when it's not active

  padding:0;
  margin:0;

  list-style:none;

  background-color:$colorNeutral;
  border:1px solid $colorPrimary;
  border-bottom-left-radius:30px;
  border-bottom-right-radius:30px;
}


[data-field-type="address"] .input-suggestions li {
  padding:15px 30px;
  cursor:pointer;
}

[data-field-type="address"] .input-suggestions .selected,
[data-field-type="address"] .input-suggestions li:hover {
  color:$colorNeutral;
  background:$colorAction;
}

.input-suggestions .input-value {
  opacity:0.5;
}


@media screen and (max-width:767px) {
  [data-field-type="address"] .input-suggestions li {
    padding:5px 15px;
  }
}


@media screen and (max-width:767px) {
  [data-field-type="address"] .input-suggestions li {
    padding:5px 15px;
  }

  //Limit height so it is not cut off by our forms overflow:hidden prop
  .touched[data-field-type="address"] .input-suggestions {
    max-height:240px;
  }
}





/***********************************************************************************************************************
*   4.  Special Input Decorations
************************************************************************************************************************/

[class*="prefix"] .form-input:after,
[class*="postfix"] .form-input:after {
  display: block;
  position: absolute;
  padding:0 1.5rem;
  bottom:0;
  line-height:$inputHeight;
  color: $colorSolemn;
  font-size: 1em;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
}

[class*="postfix"] .form-input:after {
  text-align: right;
  border-left-color: transparent;
  border-left: 1px solid $colorSolemn;
  right: 0;
}

[class*="prefix"] .form-input:after {
  text-align: left;
  border-right-color: transparent;
  border-right: 1px solid $colorSolemn;
  left: 0;
}


/* Define Types Here */

.postfix-currency .form-input:after,
.prefix-currency .form-input:after {
  content:$currencyCode;
}


.postfix-currency input {
  text-align:right;
  padding-right:4.5em;
}

.prefix-locale .form-input:after {
  content: $countryCode;
}

.prefix-countrycode .form-input:after {
  content: $phoneAreaCode;
}

.prefix-countrycode .form-input input {
  padding-left:4em;
}

.prefix-locale .form-input input {
  padding-left:3em;
}

.postfix-years .form-input:after {
  content: $numYearsTxt;
}

.postfix-m2 .form-input:after {
  content: 'm2';
}



@media screen and (max-width:800px) {
  [class*="prefix"] .form-input:after,
  [class*="postfix"] .form-input:after {
    padding:0 1.5rem;
    line-height:$inputHeight * 0.9;
  }
}




/***********************************************************************************************************************
*   Searching Animation
************************************************************************************************************************/

.prefix-search .form-input input {
  padding-left:calc(#{$inputHeight} + 20px);
}

.prefix-search .form-input:after {
  font-family:"Font Awesome\ 5 Pro";
  content:'\f689';
  font-size:2rem;
  font-weight:500;

  border-top-left-radius:30px;
  border-bottom-left-radius: 30px;

  //transition:all ease-in-out 0.3s;
}

.prefix-search .working .form-input:after {
  background-color:$colorAction;
  color:$colorNeutral;

  border-top:1px solid $colorPrimary;
  border-left:1px solid $colorPrimary;
  border-bottom:1px solid $colorPrimary;

  //transition:all ease-in-out 0.3s;
}


.prefix-search .working .form-input:before {
  position:absolute;
  bottom:0;
  z-index:999;

  padding:0 1.5rem;
  line-height: $inputHeight;
  font-family:"Font Awesome\ 5 Pro";
  font-size:2rem;
  font-weight:bold;
  color:$colorNeutral;

  border-bottom-left-radius: 0;

  content:'\f110';
  animation-name: rotate;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  //transition:all ease-in-out 0.3s;
}

.prefix-search .working .form-input:after {
  color:$colorAction;
  border-bottom-left-radius: 0;

  //transition:all ease-in-out 0.3s;
}


@media screen and (max-width:767px) {
  .prefix-search .form-input input {
    padding-left:calc(#{$inputHeight} + 5px);
  }
}





/***********************************************************************************************************************
*   5.  Tooltips
************************************************************************************************************************/

[data-toggle="tooltip"], .checkbox + [data-toggle="tooltip"] {
  position:absolute;
  top:35%;
  right:15px;
  width:26px;
  height:26px;
  background:url(../images/icon_info.svg) no-repeat;
}

label ~ [data-toggle="tooltip"] {
  top:50%;
}

[data-toggle="tooltip"] .tooltiptext {
  width: 275px;
  height: 200%;
  background-color: $colorPrimary;
  color: #fff;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  right: 0;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  top: -45%;
  margin-right:150%;
  z-index: -1;
}


/* Tooltip arrow */
[data-toggle="tooltip"] .tooltiptext::after {
  content: "";
  position: absolute;
  top: 35%;
  left: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent $colorPrimary;
}

/* Show the tooltip text when you mouse over the tooltip container */
[data-toggle="tooltip"]:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}