
/* Small Desktop */
@media screen and (max-width:990px) and (min-width:768px) {


}


/* Tablets */
@media screen and (max-width:990px) {
  footer .content-row,
  footer .content-col {
    margin:20px 0;
  }


  .td-text-dropdown input {
    font-size:4rem;
    line-height:4rem;
    padding:0 12rem 0 12px;
  }

  .custom-select .measurement-unit {
    font-size:1.8rem;
    padding:18px 8px 8px 8px;
  }


  //Bullets with numbers
  ol.large-bullets li:before {
    display:block;
    margin:15px auto;
  }
  ol.large-bullets li{
    text-align:center;
    margin-bottom:40px;
  }

  ol.large-bullets {
    padding:0;
  }

}


/* Large Mobile Devices */
@media screen and (max-width:767px) {
  .product-page {
    margin-top:20px;
  }

  .form-custom-content {
    padding:0;
  }

  .left-custom-content {
    padding:0 5%;
  }

  .frontpage .extendable-content {
    display:none;
  }

  //Monthly Payment Fields
  .monthly-payment-label {
    display:block;
    margin:0 0 20px 0;
    font-size: 1.8rem;
    font-weight: bold;
    text-align:right;
    color: $colorSolemn;
    float:none;
    transform: translateY(60%);
  }

  .monthly-payment-value {
    display:block;
    font-size: 4rem;
    font-weight: bold;
    text-align:right;
    color: $colorSolemn;
    float: none;

    .monthlyPayCurrency {
      font-size: 1.8rem;
    }

  }

}

/* Medium Mobile Devices */
@media screen and (max-width:600px) {


}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

  .form-custom-content ~ div {
    width:100%;
  }
}
