//Hide elements on mobile and tablet per default
.display-mobile,
.display-tablet {
  display:none;
}


//Only display on mobiles and tables
@media screen and (max-width:800px) {
  .display-mobile,
  .display-tablet {
    display:block;
  }
}
