//Common Styles
.progress-wrapper {
  @extend .container;
}


.progress-legend {
  color:$colorPrimary;
}


//Progress Bar
.progress-bar {
  height:5px;
  float:none;
  border-bottom:1px solid $colorAction;
}

.progress-bar .progress-bar-completion {
  height:100%;
  background-color:$colorAction;

  transition:all ease-in-out 0.5s;
}



//Progress Bullet Line
.progress-type-bulletline {
  counter-reset:progressIndex;
  height:40px; //set height so absolute pos bullets don't end up outside formwrapper
}


.progress-bulletline {
  border-bottom:1px solid $colorAction;
}

.progress-bullet {
  width:30px;
  height:30px;
  background-color:$colorAction;

  border-radius:50%;

  transition:all ease-in-out 0.5s;
}

.progress-bullet:before {
  display:inline-block;
  width:30px;
  height:30px;

  color:$colorNeutral;
  text-align:center;
  line-height:30px;

  counter-increment:progressIndex;
  content:counter(progressIndex);

  transition:all ease-in-out 0.5s;
}

.progress-bullet.active {
  width:40px;
  height:40px;
  transition:all ease-in-out 0.5s;
}

.progress-bullet.active:before {
  width:40px;
  height:40px;
  line-height:40px;

  font-size:1.1em;
  transition:all ease-in-out 0.5s;
}


//Progress List