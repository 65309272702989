/***********************************************************************************************************************
*                             Styles for FORM FUNCTIONALITY and INPUT COMPONENTS
                                  TABLE OF CONTENTS

*     1.    FORM HEIGHT ANIMATION
*     2.    Form Step Switch
        2.1.  Switch between steps
        2.2.  Extended steps
*     3.    Date Selector
*     4.    Custom Select
*     5.    Custom Content

************************************************************************************************************************/





/***********************************************************************************************************************
*                            PREFACE
*      Please add here only the styles related to the rf-form-builder Components. Also keep the styles clean and
*    update the table of content regularly.
************************************************************************************************************************/





/***********************************************************************************************************************
*   1.  CUSTOM CONTENT
************************************************************************************************************************/

.form-custom-content {
  padding: 5% 0px;
}

.extendable-wrapper {
  margin-top:40px;
}

.left-custom-content h1 {
  display:none;
}

.left-custom-content h4 {
  font-size:2.2rem;
  font-weight:bold;
}

.left-custom-content {
  padding-right:15%;
}

.bottom-custom-content {
  margin: 50px 0px;
  color:$colorSolemn;
}

.offer-checkbox-error{
  position: relative;
  top: -25px;
  left: 50px;
}

.offer-input-error-message {
  color: $colorError;
  position: relative;
  top: -25px;
  left: 15px;
}

.cursor-pointer {
  cursor: pointer;
}