/***********************************************************************************************************************
*   Notification
*
*   Renders warnings and other content. Typically fixed position dialogues or similar that are variations on a modal.
************************************************************************************************************************/

.notification {
  position:fixed;
  top:0;
  width:100vw;
  z-index:9999;

  padding:30px;

  background-color:$colorPrimary;
  color:$colorNeutral;

  box-shadow:4px 0 8px rgba(0,0,0,0.5);


  h1,h2,h3,h4,h5,h6 {
    color:$colorNeutral;
  }
}



/***********************************************************************************************************************
*   Notification Types
*
*   Renders different types of notifications with some default styling for each type.
************************************************************************************************************************/

.notification.info {
  background-color:$colorAction;
}

.notification.error {
  background-color:$colorError;
}

.notification.warning {
  background-color:$colorWarning;

}
