.hover-content-component {
  position:relative;
  perspective:800px;
  z-index:99;

  .hover-trigger {
    position:relative;
    z-index:10;
    cursor:pointer;
  }


  .hover-content {
    z-index:99;
    position:absolute;
    width:100%;
    padding:20px;
    background-color:$colorAction;

    text-align:left;
    line-height:1.8rem;
    color:$colorNeutral;
    backface-visibility:hidden;
  }

  .hover-content h1,
  .hover-content h2,
  .hover-content h3,
  .hover-content h4,
  .hover-content h5,
  .hover-content h6 {
    text-transform:uppercase;
    color:$colorNeutral;
  }


  &.position-bottom .hover-content {
    top:calc(100% + 15px);
  }

  &.position-bottom .hover-content:before {
    content:'';
    position:absolute;
    top:-12px;
    right:5%;
    width: 0;
    height: 0;

    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $colorAction;
  }

  .hover-trigger ~ .hover-content {
    transition:all ease-out 0.5s;
    transform: rotate3d(1,0,0,-180deg);
    transform-origin: top;
    transform-style:preserve-3d;
    z-index:999;
    opacity:0;
  }

  .hover-content.active,{
    transition:all ease-in 0.3s;
    transform: rotate3d(1,0,0,0);
    box-shadow:0 10px 10px rgba(0,0,0,0.4);
    opacity:1;
  }
}