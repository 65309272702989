


/***********************************************************************************************************************
*   1.  General navigation
************************************************************************************************************************/

.nav-list {
  padding:0;
  margin:0;
}

.nav-list li {
  list-style-type: none;
  padding: 15px 15px;
}

.nav-list li a {
  text-decoration:none;
  color:#fff;
}




/***********************************************************************************************************************
*   2.  Site Primary Navigation
************************************************************************************************************************/





/***********************************************************************************************************************
*   3. Links
************************************************************************************************************************/

a {
  color: $colorAction;
  transition:all ease-out 0.6s;
}

a:hover {
  color: darken($colorAction, 10%);
  text-decoration:none;
  transition:all ease-in 0.2s;
}

.nav-link {
  position:relative;
  display:block;
  margin:5px 0;
}

// Should refactor this
.nav-action {
  cursor: pointer;
}

.nav-previous {
  position:relative;
  float:right;
  display:inline-block;
  padding:0;
  margin:0;
  color:$colorAction;
  text-transform:uppercase;
  min-height:$inputHeight;
  cursor:pointer;
  border:2px solid transparent;
}

.nav-previous a {
  line-height:$inputHeight;
}

.nav-previous a:before {
  position:absolute;
  content:'';

  left:-25px;

  background: url($imgArrowLeft) no-repeat center;
  background-size:contain;
  width: 17px;
  height:$inputHeight;
}

.btn.nav-previous {
  background: transparent;
  color:$colorAction;
}

.btn.nav-previous:hover {
  color:$colorAction;
}



@media screen and (max-width:767px) {
  .nav-previous {
    float:none;
    left:5px;
  }

  .nav-previous {
    min-height:$inputHeight * 0.9;
  }
}


/***********************************************************************************************************************
*   2. Navigation Shield
************************************************************************************************************************/

.nav-shield {
  opacity: 0;
  background: $colorSolemn;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.nav-shield.active {
  opacity: 0.2;
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  z-index: 999999;
}


//Buttons to show on mobile in bottom of site to navigate to application
nav.nav-to-application {
  position:fixed;
  z-index:99999;

  right:0;
  bottom:0;
  left:0;
}

nav.nav-to-application .btn {
  border-radius:0;
  width:100%;
}