/***********************************************************************************************************************
* Text Color Utils
************************************************************************************************************************/
.color-primary {
  color:$colorPrimary;
}
.color-action {
  color:$colorAction;
}
.color-error {
  color:$colorError;
}
.color-warning {
  color:$colorWarning;
}
.color-neutral {
  color:$colorNeutral;
}
.color-background {
  color:$colorBackground;
}



/***********************************************************************************************************************
* Background Color Utils
************************************************************************************************************************/
.background-primary {
  background:$colorPrimary!important;
}
.background-action {
  background:$colorAction!important;
}
.background-error {
  background:$colorError!important;
}
.background-warning {
  background:$colorWarning!important;
}
.background-neutral {
  background:$colorNeutral!important;
}
.background-background {
  background:$colorBackground!important;
}



/***********************************************************************************************************************
* colorHexToRGB
*
* Convers a hexadecimal color to an RGB value
************************************************************************************************************************/
@function colorHexToRGB ($hexColor) {
  @return "rgb(" + red($hexColor) + ", " + green($hexColor) + ", " + blue($hexColor)+ ")";
}