/***********************************************************************************************************************
*  Cookie Handler Styles
*
* Provides some default styling for the cookie handler. Will typically be placed at bottom of page.
************************************************************************************************************************/

.cookie-policy {
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  z-index:9999;

  padding:15px;

  background-color:$colorWarning;
  color:$colorNeutral;

  text-align:center;

  transition:all ease-in 0.4s;
}

.cookie-policy-accepted {
  transform:translateY(100%);

  transition:all ease-out 0.4s;
}

.cookie-policy .btn {
  display:inline-block;
  width:auto;
  line-height:3.5rem;

  margin:0 20px;
}

.cookie-policy .btn-secondary {
  border-color:$colorNeutral;
  color:$colorNeutral;
}

.cookie-policy .btn-secondary:hover {
  background-color:$colorNeutral;
  color:$colorWarning;
}

.cookie-policy .cookie-policy-link a {
  margin:0 20px;

  cursor:pointer;
  color:$colorNeutral;
  text-decoration:underline;
}


@media screen and (max-width:990px) {
  .cookie-policy-action,
  .cookie-policy-link {
    display:block;
    margin:10px 0;
  }
}





/***********************************************************************************************************************
*  Modal Cookie Styles
*
* Styles the advanced cookie modal which allows users to select which group of cookies they wish to accept. Is shown
* as a modal with some interaction options.
************************************************************************************************************************/

.modal-cookie-settings {
  .cookie-policy-link.read-more {
    display:inline-block;
  }
  .modal-component-header {
    padding-bottom:10px;
  }


  .modal-actions {
    margin-top:40px;
    text-align:center;
  }

  .cookie-group-container {
    margin:40px 0;
  }

  .cookie-group {
    @extend .row;
    margin-top:30px;
    margin-bottom:30px;
  }

  .cookie-group-description,
  .cookie-group-control {
    @include make-sm-column(6);
  }

  .cookie-group-description h4 {
    margin-bottom:0;
    text-transform:uppercase;
  }

  .cookie-group-description {
    font-size:1.3rem;
  }

  .btn {
    line-height:4.5rem;
    margin:0 20px;
    width:auto;
    text-decoration:none;
  }

  .btn-primary {
    background-color:$colorWarning;
  }

  .btn-secondary {
    border-color:$colorNeutral;
    color:$colorNeutral;
  }

  .btn-secondary:hover {
    background-color:$colorNeutral;
    color:$colorAction;
    text-decoration:none;
  }

  //Disabled input and button
  .btn-primary[disabled],
  .btn-secondary[disabled] {
    background-color:desaturate(lighten($colorWarning, 20%), 20%);
    color:transparentize($colorNeutral, 0.3);
    cursor:not-allowed!important;
  }


  //Radio styles
  .cookie-group-control .form-input {
    margin:0;
  }
  .radio-option input ~ span {
    background:transparent;
    border:1px solid $colorNeutral;
    color:$colorNeutral;
    line-height:4.5rem;
  }

  .radio-option:first-child input ~ span {
    border-right:none;
  }

  .radio-option input:checked ~ span {
    background:$colorNeutral;
    color:$colorAction;
  }

  @media screen and (max-width:600px) {

    .modal-component-content {
      font-size:1.4rem;
    }

    .modal-actions {
      margin-top:20px;
      text-align:center;
    }

    .btn {
      margin:0 5px 10px 5px;
      line-height:3.2rem;
    }

    .radio-option input ~ span {
      line-height:3.2rem;
    }
  }
}