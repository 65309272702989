/***********************************************************************************************************************
*   Inline Offers
*
*   Inline offers are conditional offers that are rendered inside the main form. They are usually TM leads and are
*   triggered by a condition such as user selecting a value from a field. They are designed to be visually separate from
*   the main form content.
************************************************************************************************************************/

.offer-inline {
  padding: 40px;
  background-color: $colorAction;
  color: $colorNeutral;
  margin-bottom:2em;

  h1, h2, h3, h4, h5, h6 {
    color: $colorNeutral;
  }

  a {
    cursor: pointer;
  }

  .form-window .step {
    margin-bottom:20px;
  }

  .offer-consents {
    font-size:1.2rem;
  }

  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

@media screen and (max-width:500px) {
  .offer-inline {
    margin-top:15px;
  }
}




/***********************************************************************************************************************
*   Actions
*************************************************************************************************************************/
.offer-inline .nav-section {
  text-align:center;
}

.offer-inline .nav-section .next-step-button,
.offer-inline .nav-section .custom-button {
  display:inline-block;
  vertical-align:top;
}

.offer-inline .nav-section .next-step-button {
  margin:0;
  padding:0;
  width:auto;
  float:none;
}

.offer-inline .nav-section .next-step-button .btn {
  background-color:$colorAction;
  color:$colorNeutral;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.offer-inline .nav-section .custom-button {
  width:auto;
}

.offer-inline .nav-section .custom-button a {
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  position:relative;
  color:$colorNeutral;
  background-color:transparent;
  border:1px solid $colorAction;
}

.offer-inline .invalid .nav-section .next-step-button input[type="button"] {
  background-color:desaturate(lighten($colorAction, 20%), 20%);
  color:transparentize($colorNeutral, 0.3);
}


@media screen and (max-width:600px) {
  .offer-inline .nav-section .custom-button,
  .offer-inline .nav-section .next-step-button {
    width:100%;
    padding:0 15px;
  }

  .offer-inline .nav-section .custom-button a {
    display:block;
    border-radius:30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .offer-inline .nav-section .next-step-button input[type="button"] {
    display:block;

    border-radius:30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}




/***********************************************************************************************************************
*   Collapsed view
************************************************************************************************************************/

.offer-inline.offer-inline-hidden {
  padding:10px 20px;
  background-color:transparent;
  border:1px solid $colorNeutral;
  color:$colorNeutral;

  .offer-inline-wrapper {
    text-align:center;
  }

  .offer-inline-content,
  .control-show {
    display:inline-block;
    vertical-align:middle;
    color:$colorNeutral;
  }

  .control-show {
    position:relative;
    width:45px;
    height:45px;
    margin-right:20px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .control-show:after {
    position:absolute;
    left:0;
    top:0;
    content:'\f070';
    font-family:'Font Awesome 5 Pro';
    font-size:3rem;
    text-indent:0;
    text-align:center;
    line-height:45px;
  }

  .offer-inline-content h4 {
    color:$colorNeutral;
    margin:0;
  }

  .offer-inline-content a {
    color:$colorNeutral;
  }
}
