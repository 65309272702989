/***********************************************************************************************************************
*   Exendable Content
*
* Provides content that can expand to show additional content
************************************************************************************************************************/


.extendable-content-content {
  transition: all ease-in-out 0.2s;
  margin:0;
  padding-top:10px;
}

.extendable-content-content,
.extendable-content-content p {
  color:$colorPrimary;
}

.extendable-content-wrapper {
  overflow:hidden;
  max-height: 0;
  padding: 0 0 0 45px;
  text-align: left;
  transition: all ease-in-out 0.3s;
}

.extendable-content-wrapper.active {
  max-height:120px;
  transition: all ease-in-out 0.3s;
}

.extendable-content-nav {
  position: relative;
}

.extendable-content-icon {
  width:40px;
  height:40px;
  background: url('../images/bullet_check.svg') no-repeat;
  background-size:cover;
  position: absolute;
  top: 50%;
  transform:translateY(-50%);
}


.extendable-content-nav .extendable-content-title {
  margin: 0;
  line-height: 1em;
  font-weight: light;
  padding: 0 0 0 55px;
}

.extendable-content-title:hover {
  cursor: pointer;
}

.extendable-content-display {
  position: relative;
  padding: 20px 0px;
}

