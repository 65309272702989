// Fonts
html, body {
  font-size:62.5%;
}


$fontHeadings: Arial, Helvetica Neue, Helvetica, sans-serif !default;
$fontBody: Arial, Helvetica Neue, Helvetica, sans-serif !default;

//Text Size
$fontSizeBody: 1.6rem !default;
$fontSizeH1: $fontSizeBody*2.8 !default;
$fontSizeH2: $fontSizeBody*2 !default;
$fontSizeH3: $fontSizeBody*1.5 !default;
$fontSizeH4: $fontSizeBody*1.25 !default;
$fontSizeH5: $fontSizeBody !default;
$fontSizeH6: $fontSizeBody * 0.75 !default;

@media screen and (max-width:540px) {

}