/***********************************************************************************************************************
*   4.  Color Classes
*
*   Can be used to add colors by class in the editor so CSS does not need modification. These classes should only
*   modify background and text color.
************************************************************************************************************************/


.background-primary.background-primary {
    background-color:$colorPrimary;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }

    a, a:hover {
        color: $colorAction;
    }

    .btn {
        background:$colorAction;
        color:$colorPrimary;
        border:1px solid $colorAction;
    }
    .btn:hover {
        background:transparent;
        color:$colorAction;
        border:1px solid $colorAction;
    }

}



.background-transparent.background-transparent {
    background-color:transparent;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }

    a, a:hover {
        color: $colorAction;
    }

    .btn.btn:hover {
        color:$colorNeutral;
    }

}



.background-white.background-white,
.background-neutral.background-neutral {
    background-color:$colorNeutral;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorPrimary;
    }

    a, a:hover {
        color:$colorPrimary;
    }

    .btn.btn {
        color:$colorNeutral;
    }
}


.background-color1.background-color1 {
    background-color: $colorContentBg1;
    color:$colorNeutral;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }
}


.background-color2.background-color2 {
    background-color: $colorContentBg2;
    color: $colorNeutral;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }
}


.background-color3.background-color3 {
    background-color: $colorContentBg3;
    color: $colorNeutral;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }
}



.background-action.background-action {
    background-color: $colorAction;
    color: $colorNeutral;

    h1, h2, h3, h4, h5, h6, p, a, a:hover {
        color: $colorNeutral;
    }

    .btn.btn {
        background-color:$colorPrimary;
        color:$colorNeutral;
        border:1px solid $colorPrimary;
    }

    .bnt:hover {
        background-color:transparent;
        color:$colorPrimary;
        border-color:$colorPrimary;
    }
}

