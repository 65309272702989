

/***********************************************************************************************************************
*   Slider Layout
************************************************************************************************************************/
.slider-wrapper {
  position:relative;
  //padding-bottom:100px; //Make room for controls
}

.slider-wrapper .slider-layers {
  height:400px; //Set a reasonable minimum height
}

.slider-wrapper .slider-layers .step {
  margin:0;
  padding:0;
}


.slider-wrapper .slide-window {
  height: 100%;
}


//Make room for controls under content
.slider-wrapper.slider-has-controls .slider-layer .slider-item-content {
  padding-bottom:80px;
}



/***********************************************************************************************************************
*   Slider Controls
************************************************************************************************************************/

.slider-controls {
  position:absolute;
  width:200px;
  z-index:10;
}


.slider-controls-bar input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  border: none;
  padding: 0;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}

.slider-controls-bar input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.slider-controls-bar input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.slider-controls-bar input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* THUMB */

/* Special styling for WebKit/Blink */
.slider-controls-bar input[type=range]::-webkit-slider-thumb {
  border: none;
  height: 24px;
  width: 40px;
  background: #fff;
  cursor: pointer;
  border-radius: 12px;
  margin-top:1px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */

}

/* All the same stuff for Firefox */
.slider-controls-bar input[type=range]::-moz-range-thumb {
  border: none;
  height: 24px;
  width: 40px;
  background: $colorNeutral;
  cursor: pointer;
  border-radius: 12px;

}

/* All the same stuff for IE */
.slider-controls-bar input[type=range]::-ms-thumb {
  border: none;
  height: 20px;
  width: 20px;
  background: $colorNeutral;
  cursor: pointer;
  border-radius: 25px;
}

/**** TRACK **/

.slider-controls-bar input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 28px;
  padding:0 1px;
  cursor: pointer;
  background: rgba(255,255,255,0.2);
  border-radius: 14px;
  border: 1px solid $colorNeutral;
}

.slider-controls-bar input[type='range']::-moz-focus-outer {
  border: 0;
}

.slider-controls-bar input[type=range]::-moz-range-track {
  width: 100%;
  height: 28px;
  padding: 0 2px;
  cursor: pointer;
  background: rgba(255,255,255,0.2);
  border-radius:14px;
  border:1px solid #fff;
}

.slider-controls-bar input[type=range]::-ms-track {
  width: 100%;
  height: 28px;
  cursor: pointer;
  background: rgba(255,255,255,0.2);
  border:1px solid #fff;
  border-radius:14px;
}

.slider-controls-bar input[type=range]::-ms-fill-lower {
  background: rgba(255,255,255,0.2);
}

.slider-controls-bar input[type=range]:focus::-ms-fill-lower {
  background: rgba(255,255,255,0.2);
}

.slider-controls-bar input[type=range]::-ms-fill-upper {
  background: transparent;
}

.slider-controls-bar input[type=range]::-moz-range-progress {
  background: transparent;
}



/* Slider Controls Position */
.slider-controls.centered.bottom {
  bottom:30px;
  left:50%;
  transform:translateX(-50%);
}


/* Remove focus, as it looks silly */
.slider-controls-bar input[type=range]:active,
.slider-controls-bar input[type=range]:focus {
  outline:none;
  box-shadow:none;
}






/***********************************************************************************************************************
*   3.  Animations
************************************************************************************************************************/
.slider-wrapper:after {
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  opacity:1;
  content:'';
  z-index:99;

  background-color:$colorPrimary;

  background-size: 200px auto;
  background-position: 50%;
  background-repeat: no-repeat;

  animation: animateOut;
  animation-duration: 1s;
  animation-delay:1.2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode:forwards;
}


@keyframes animateIn {
  0% {
    top:0;
    opacity:0;
  }
  99% {
    top:0;
    opacity:1;
  }
  100% {
    top:-100%;
  }
}


@keyframes animateOut {
  0% {
    height:100%;
    opacity:1;
  }
  99% {
    height:100%;
    opacity:0;
  }
  100% {
    opacity:0;
    height:0;
  }
}