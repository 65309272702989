/***********************************************************************************************************************
*   6.  Repeater
*
*  The repeater enables a group of fields to be inserted by the user. The repeater has two states, active and
*  inactive. There are two elements rendered for those states. These are always rendered, so it is necessary
*  to use the active/inactive state to determine which element to show and which to hide.
************************************************************************************************************************/

.section.repeater {
  padding-top:0;
}

.refinance-nav-menu:after {
  content: "";
  display: table;
  clear: both;
}

.refinance-nav-menu {
  margin-bottom: 20px;
}

.refinance-nav-menu .btn {
  background-color:transparent;
  color:$colorAction;
  border:1px solid $colorAction;
  border-right:none;
  border-radius:0;
}

.refinance-nav-menu .btn:last-child{
  border-right:1px solid $colorAction;
}

/* Repeater Counter */
body {
  counter-reset: refinanceCounter;
}

.rfRepeater_debtInfo [class^="item-"] {
  position:relative;
  padding:40px;
  margin-top:60px;

  border:1px solid $colorAction;
}

.rfRepeater_debtInfo [class^="item-"]:before {
  display:block;
  position:absolute;
  width:60px;
  height:60px;

  top:-30px;
  left:50%;
  transform:translateX(-50%);

  font-size:4rem;
  line-height:60px;
  text-align:center;
  color:$colorAction;

  border:1px solid $colorAction;
  background-color:$colorContentBg1;

  counter-increment: refinanceCounter;
  content: counter(refinanceCounter);
}





/***********************************************************************************************************************
*  Repeater Items
************************************************************************************************************************/

.repeater-item {
  position:relative;
  color:$colorPrimary;
}


.repeater-item {
  border:1px solid $colorPrimary;
  border-top:none;
}

.repeater-item:first-child {
  border-top:1px solid $colorPrimary;
}


//Set a counter
.repeater {
  counter-reset: repeaterCount;
}

.repeater-item {
  padding-left:60px;
}

.repeater-item:before {
  counter-increment:repeaterCount;
  content:counter(repeaterCount);
  position:absolute;
  top:55px;
  left:5px;
  display:block;
  width:40px;
  height:40px;

  background-color:$colorPrimary;
  color:$colorNeutral;
  border-radius:50%;

  text-align:center;
  line-height:40px;
  font-size:1.25em;
}





/***********************************************************************************************************************
*  Repeater States
************************************************************************************************************************/

.repeater-item.active {
  padding-bottom:20px;
  padding-right:20px;
}


//Repeater Item Inactive
.repeater-item.inactive {
  padding-top:15px;
  padding-bottom:15px;

  //background-color:transparentize($colorPrimary, 0.85);
}




/***********************************************************************************************************************
*  Repeater Controls
************************************************************************************************************************/

.repeater-controls {
  margin:20px 0 40px;
  text-align:center;
}

.repeater-controls .btn {
  padding:0 60px;
  width:auto;
}

.repeater-item.inactive:before {
  top:50%;
  transform:translateY(-50%);
  left:5px;
}




//Basic Field Display

.repeater-item-row {
  display:flex;
  margin:0;
  flex-wrap:wrap;
}

.repeater .repeater-item-field {
  flex:1;
  margin:0 15px;
}

.repeater .repeater-item-field .field-value {
  display:inline-block;
  font-weight:bold;
  padding-left:5px;
}





/***********************************************************************************************************************
*  Repeater Item Controls
************************************************************************************************************************/

.repeater-item-controls {
  display:block;
  color:$colorNeutral;
  text-align:center;
}

.repeater-item.active .repeater-item-controls {
  margin:10px auto;
}

.repeater-item-control {
  margin:0 5px;
  display:inline-block;
  width:60px;
  line-height:$inputHeight;

  cursor:pointer;
}

.repeater-item-control.btn {
  line-height:$inputHeight;
  height:auto;
}

.repeater-item-control:before {
  display: block;
  width:60px;
  height:60px;

  border-radius:50%;
  background:$colorNeutral;

  text-align:center;
  line-height:60px;
  font-family: "FontAwesome";
  font-size:2.4rem;
}

.control-label {
  display:none;
}

.repeater-item-control.control-disabled {
  opacity:0.5;
  cursor:not-allowed;
}

.repeater-item-control.control-delete:before {
  background:$colorError;
  color:$colorNeutral;
  content:'\f1f8';

}

.repeater-item-control.control-edit:before {
  background:$colorWarning;
  color:$colorNeutral;
  content:'\f040';
}


.repeater-item.active .repeater-item-control.control-accept .control-label:before {
  content:'\f00c';
  font-family: "FontAwesome";
  margin-right:5px;
}

.repeater-item.active .repeater-item-control.control-accept {
  display:inline-block;
  padding:0 40px;
  width:auto;
  line-height:$inputHeight;
  text-transform:uppercase;
  text-align:center;

  border: 2px solid transparent;
  background:$colorAction;
  color:$colorNeutral;

}

.repeater-item-control.control-accept .control-label {
  display:inline-block;
}




//Inactive controls {
.repeater-item.inactive .repeater-item-controls {
  position:absolute;
  right:5px;
  top:50%;
  transform:translateY(-50%);
}

.repeater-item.inactive .repeater-item-controls .repeater-item-control,
.repeater-item.inactive .repeater-item-controls .repeater-item-control:before {
  width:40px;
  height:40px;
  line-height:40px;
  font-size:1em;
}





/***********************************************************************************************************************
*  Expanded/Collapsed Elements
************************************************************************************************************************/

.repeater-item-compact {
  padding: 0 100px 0 0;
}


.repeater-item.active .repeater-item-expanded {
  height:auto;
  opacity:1;

  transition:all ease-in-out 0.5s;
}

.repeater-item.active .repeater-item-compact {
  height:0;
  opacity:0;
  overflow:hidden;

  transition:all ease-in-out 0.5s;
}

.repeater-item .repeater-item-expanded {
  height:0;
  opacity:0;
  overflow:hidden;

  transition:all ease-in-out 0.5s;
}

.repeater-item .repeater-item-compact {
  height:auto;
  opacity:1;

  transition:all ease-in-out 0.5s;
}

.repeater .refinance-radio {
  flex:none;
  width:50%;
}





/***********************************************************************************************************************
*  Repeater Refinance Validator
************************************************************************************************************************/

.refinance-validator {
    margin:40px 0;
    padding:20px;
    border:1px solid $colorPrimary;
}

.refinance-validator-heading {
    font-weight:bold;
}

.refinance-validator-datalist {
    width:75%;
    margin:0 auto;
}

.refinance-validator-datalist .result {
    border-top:1px solid transparentize($colorPrimary, 0.5);
}

.refinance-validator-datalist dt,
.refinance-validator-datalist dd {
    width: 48%;
    width: calc(50% - 10px);
    margin: 8px 0;
    padding:0;
    display: inline-block;
    vertical-align: middle;
}

.refinance-validator-datalist dt {
    text-align: right;
    padding-right: 5px;
}

.refinance-validator-datalist dd {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    padding-left: 5px;
}

/* Validation Error / Refinance amount exceeds max */
.refinance-exceeded.refinance-validator {
    border-color:$colorError;
}

.refinance-exceeded.refinance-validator:before {
    content:'\f071';
    display:block;
    position:relative;
    margin-bottom:20px;

    font-family:'Font Awesome 5 Pro';
    font-size:3.2rem;
    text-align:center;
    line-height:1.5em;
    color:$colorError;
}

.refinance-exceeded.refinance-validator .result dt,
.refinance-exceeded.refinance-validator .result dd {
    color:$colorError;
}

.refinance-exceeded .repeater-item .radio-option input[type="radio"]:checked ~ span {
    background-color:$colorError;
    border-color:$colorError;
    color:$colorNeutral;
}

.refinance-exceeded .repeater-item .radio-option span {
  border-color:$colorError;
}




@media screen and (max-width:990px){
    .refinance-validator-datalist {
        width:100%;
    }
}


@media screen and (max-width:660px){
    .refinance-validator-datalist dd,
    .refinance-validator-datalist dt {
        width:70%;
        text-align:left;
    }

    .refinance-validator-datalist dd {
        width:30%;
        text-align:right;
    }
}



/***********************************************************************************************************************
*  Repeater Responsive Styling
************************************************************************************************************************/

@media screen and (max-width:1150px) {
  .repeater-item-row {
    display:block;
  }
  .repeater .repeater-item-field {
    flex:none;
  }

  .repeater-item-compact .repeater-item-field {
    clear:both;
    width:100%;
  }

  .repeater-item-compact .repeater-item-field .compact-label,
  .repeater-item-compact .repeater-item-field .compact-value {
    float:left;
    width:50%;
  }

  .refinance-validator {
    width:auto;
    line-height:1em;
  }
}

@media screen and (max-width:600px) {
  .repeater-items {
    margin-top:30px;
  }

  .repeater-item-compact {
    padding:10px;
  }

  .repeater-item {
    padding:20px 0 10px 0;
  }

  .repeater-item:before,
  .repeater-item.inactive:before{
    top: -20px;
    left: 50%;
    transform:translateX(-50%);
  }

  .repeater .repeater-item-field {
    margin:0;
  }

  .repeater-item-expanded {
    padding:0;
  }

  .repeater-item:before,
  .repeater-item.inactive:before {
  }


  //Controls
  .repeater-item-controls {
    text-align:center;
  }
  .repeater-item .repeater-item-controls {

  }

  .repeater-item.inactive .repeater-item-control,
  .repeater-item.inactive .repeater-item-control:before {
    height:40px;
    width:40px;
    line-height:4rem;
    border-radius:20px;
  }

  .repeater-item.active .repeater-item-control.control-accept {
    padding:0 20px;
  }

  .repeater-item.active .repeater-item-controls {
    position:relative;
    top:auto;
    right:auto;
    bottom:auto;
    left:auto;
    transform:none;
  }


  //Inactive Rows
  .repeater-item.inactive {

  }

  .repeater-item.inactive .repeater-item-expanded {
    display:none;
  }

  .repeater-item.inactive:before {
  }

  .repeater .repeater-item-field {
    width:100%;
  }

  .repeater .repeater-item-field .field-value,
  .repeater .repeater-item-field .field-label {
    font-size:0.825em;
  }

  //Radio
  .repeater .refinance-radio {
    flex:none;
    width:100%;
  }
  .repeater .refinance-radio .radio-option {
    width:75%;
    display:inline-block;
  }
}
