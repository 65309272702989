/***********************************************************************************************************************
*   This stylesheet includes the general content styling as well as classes in content from CSS that should be styled.
************************************************************************************************************************/





/***********************************************************************************************************************
*   1. Responsive Images
************************************************************************************************************************/

img {
  max-width:100%;
  height:auto;
}







/***********************************************************************************************************************
*   2.  CMS Styles
************************************************************************************************************************/

.aligncenter,
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align:center;
}

.alignright,
img.alignright {
  float:right;
  margin:0 0 1em 1em;
  text-align:right;
}

.alignleft,
img.alignleft {
  float:left;
  margin:0 1em 1em 0;
  text-align:left;
}

a img.alignright {
  float:right;
  margin:0 0 1em 1em
}

a img.alignleft {
  float:left;
  margin:0 1em 1em 0
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}


blockquote.left {
  margin-right: 20px;
  text-align: right;
  margin-left: 0;
  width: 33%;
  float: left;
}
blockquote.right {
  margin-left: 20px;
  text-align: left;
  margin-right: 0;
  width: 33%;
  float: right;
}


.gallery dl {}
.gallery dt {}
.gallery dd {}
.gallery dl a {}
.gallery dl img {}
.gallery-caption {}

.size-full {}
.size-large {}
.size-medium {}
.size-thumbnail {}