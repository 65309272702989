/***********************************************************************************************************************
*   Overlay Loading
************************************************************************************************************************/

.overlay-loading {
  position:fixed;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100vw;
  height:100vh;
  z-index:-5;
  opacity:0;
}

.overlay-loading h3 {
  text-align:center;
}

.overlay-loading.active {
  z-index:9999;
  background-color:transparentize($colorPrimary, 0.1);
  transition:opacity ease-in 0.3s;
  opacity:1;
}

.overlay-loading .overlay-container {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  opacity: 1;

  transition:opacity ease-out 0.3s;
}

.overlay-loading .actions{
  text-align:center;
}

.overlay-loading .btn {
  width:auto;
  color:$colorNeutral;
}

.overlay-animation-submitting {
  text-align:center;
}

.overlay-animation-submitting img {
  margin:30px 0;
  max-width:360px;
}

.ellipsis-animated span {
  animation-name:fadeInOut;
  animation-duration:0.9s;
  animation-iteration-count:infinite;
}
.ellipsis-animated span:nth-child(1n+2) {
  animation-delay:0.3s;
}

.ellipsis-animated span:nth-child(1n+3) {
  animation-delay:0.6s;
}

@keyframes fadeInOut {
  0%      { opacity: 1; }
  50%     { opacity: 0; }
  100%    { opacity: 1; }
}




/***********************************************************************************************************************
*   3.  Loading Spinner
************************************************************************************************************************/

.loading-spinner {
  margin:0 auto;
  display: block;
  position: relative;
  width: 94px;
  height: 94px;

}

.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 81px;
  height: 81px;
  border: 4px solid $colorAction;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $colorAction transparent transparent transparent;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}




@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}