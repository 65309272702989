/***********************************************************************************************************************
*   1.  Input Containers
************************************************************************************************************************/

.form-input,
.form-description {
  position:relative;
  width: 100%;
  margin: 15px 0px;
}

.form-input:after {
  content: "";
  clear: both;
  display: table;
}



@media screen and (max-width:800px) {
  .form-input,
  .form-description {
    margin: 15px 0;
  }
}


@media screen and (max-width:500px) {
  .form-input,
  .form-description {
    margin: 15px 0 0 0;
  }
}



/***********************************************************************************************************************
*   2.  Inputs
************************************************************************************************************************/

input,
input[type="text"],
input[type="textarea"],
input[type="email"],
input[type="number"],
select {
  width:100%;
  line-height:$inputHeight;
  padding:0 14px;
  color: $colorSolemn;
  background-color: $colorNeutral;
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance:none;
  font-size:1.125em;
  box-sizing:border-box;
  border: 1px solid $colorSolemn;
  border-radius:0;
}

select {
  line-height:$inputHeight;
  background-image: url($imgArrowDown);
  background-repeat:no-repeat;
  background-position:98% center;
  background-size:1.4em;
}



@media screen and (max-width:800px) {
  input,
  input[type="text"],
  input[type="textarea"],
  input[type="email"],
  input[type="number"],
  select {
    line-height:$inputHeight * 0.9;
    font-size:1em;
  }
}




/***********************************************************************************************************************
*   3.  Labels
************************************************************************************************************************/

.form-description label {
  display:block;
}

label.checkbox {
  position: relative;
  max-width: 37px;
  float: left;
  overflow: hidden;
  min-height: 35px;
  margin: 0;
  font-size:1.125em;
  font-weight:normal;
}

.slider-label {
  margin-bottom:0;
}



/***********************************************************************************************************************
*   4.  Checkboxes
************************************************************************************************************************/

input[type="checkbox"] {
  position:absolute;
  left:-999em;
}

input[type="checkbox"] ~ span {
  display: inline-block;
  float: left;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  background: $colorNeutral;
  border:1px solid $colorAction;
  padding:2px;
}


input[type="checkbox"] ~ span:before {
  width:100%;
  height:100%;
  display:block;
  font-size: 3rem;
  text-align: center;
  line-height: 1em;
  content: '';
  color: $colorAction;
  background-color:transparentize($colorAction, 0.8);
  transition: all ease-in-out 0.2s;
  background-size:contain;
}

input[type="checkbox"]:checked ~ span:before {
  transition: all ease-in-out 0.2s;
  background: url($imgCheckmark) no-repeat center;
  background-size:contain;
}


.checkbox ~ p {
  float:right;
  width:calc(100% - 50px);
  color: $colorPrimary;
}




/***********************************************************************************************************************
*   4.  Radiobuttons
************************************************************************************************************************/

input[type="radio"] {
  position:absolute;
  left:-999em;
}

.radio-option {
  width:50%;
  margin-bottom: 0px;
  text-transform:none;
}

input[type="radio"] ~ span {
  display: block;
  position: relative;
  cursor: pointer;
  border: 1px solid $colorAction;
  border-right:none;
  background:$colorNeutral;
  font-weight: 400;
  text-align: center;
  color: $colorSolemn;
  line-height:$inputHeight;
  transition: all ease-in-out 0.2s;
  width:100%;
}

.radio-option:last-child span {
  border-right:1px solid $colorAction;
}

input[type="radio"]:checked ~ span {
  color: $colorNeutral;
  background-color:$colorAction;

  transition:all ease-in-out 0.2s;
}


@media screen and (max-width:800px) {
  input[type="radio"] ~ span {
    line-height: $inputHeight * 0.9;
  }
}





/***********************************************************************************************************************
*   5.  Buttons
************************************************************************************************************************/

.btn-success,
.btn,
.btn-primary,
input[type="button"]{
  display:inline-block;
  padding:0 30px;
  width:100%;

  line-height:$inputHeight;
  text-transform:uppercase;
  text-align:center;

  border: 1px solid transparent;
  background:$colorAction;
  color:$colorNeutral;
  cursor:pointer;

  text-decoration:none;
}


.btn:hover,
.btn-primary:hover,
input[type="button"]:hover {
  text-decoration:none;
}

.btn-secondary {
  border: 1px solid $colorAction;

  background:transparent;
  color:$colorAction;
}

.btn-secondary:hover,
.btn-secondary:active {
  border: 1px solid $colorAction;

  background:$colorAction;
  color:$colorNeutral;
  transition:background ease-out 0.35s;
}

.btn-success:hover,
.btn:hover {
  background:darken($colorAction, 5%);
  transition:background ease-out 0.35s;
  color:$colorNeutral;
}


@media screen and (max-width:800px) {
  .btn-success,
  .btn,
  .btn-primary,
  input[type="button"]{
    line-height:$inputHeight * 0.9;
  }
}



@media screen and (max-width:767px) {
  .btn-success,
  .btn,
  .btn-primary {
    padding:0 20px;
  }
}




/***********************************************************************************************************************
*   6.  Rangeslider
************************************************************************************************************************/

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin-top:0;
  position: relative;
  z-index: 2;
}

input[type=range]:focus {
  outline: none;
}




/* THUMB */

/* WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  margin-top: -9px;

}

body::-webkit-scrollbar-thumb {
  color: $colorAction;
}



/* Mozilla */
input[type=range]::-moz-range-thumb {
  border: none;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;

}


/* Internet Explorer */
input[type=range]::-ms-thumb {
  border: none;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}



/* TRACK */

/* WebKit/Blink */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: $colorAction;
  border-left: 3px solid $colorAction;
  border-radius: 25px;
}


/* Mozilla */
input[type='range']::-moz-focus-outer {
  border: 0;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $colorAction;
}

input[type=range]::-moz-range-progress {
  background: $colorAction;
  height:3px;
}


/* Internet Explorer */

input[type=range]::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: $colorAction;
}

input[type=range]::-ms-fill-lower {
  background: $colorAction;
  height: 3px;
}

input[type=range]:focus::-ms-fill-lower {
  background: $colorAction;
}

input[type=range]::-ms-fill-upper {
  background: transparent;
}



//Decorations for end of slider
.input-range-wrapper:before,
.input-range-wrapper:after {
  content: '';
  border: 4px solid $colorAction;
  border-radius: 25px;
  position: absolute;
  top:50%;
  margin-top:-3px;
}

.input-range-wrapper:before {
  left:0;
}

.input-range-wrapper:after {
  right:0;
}



//Set range widths when labels are used
.input-range-container {
  display:flex;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:center;
  width:100%;
  margin-top:20px;
}

.input-range-container .input-range-wrapper {
  position:relative;
  flex-grow:1;
  padding:0;
}

.input-range-container .input-range-label {
  margin:0;
  flex-basis:8rem;
  flex-shrink:0;

  font-size:1.4rem;
}

.input-range-container .input-range-label-min {
  padding-left:0;
  padding-right:10px;

  text-align:left;
}

.input-range-container .input-range-label-max {
  padding-left:10px;
  padding-right:0;

  text-align:right;
}



@media screen and (max-width:768px) {
  .input-range-container .input-range-label {
  }
}


@media screen and (max-width:550px) {
  .input-range-container .input-range-label {
    display:none;
  }

  .input-range-wrapper:before,
  .input-range-wrapper:after {
    //bottom: 21px;
  }
}





/***********************************************************************************************************************
*   5.  Input Pseudo Classes
************************************************************************************************************************/

input:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  box-shadow: rgba(0,0,0,0.2) inset 0 2px 2px;
}

input[type="range"]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  box-shadow: none;
}

.btn:focus {
  outline: none;
  border:2px solid darken($colorAction, 20%);
  text-decoration:none;
  color:$colorNeutral;
}


//Disabled inputs
input.disabled,
.disabled input,
input:disabled,
select.disabled,
.disabled select,
select:disabled,
textarea.disabled,
.disabled textarea,
textarea:disabled {
  background-color:#fff;
  opacity:0.7;
  transition:all ease-in 0.3s;
}


select:invalid,
select [disabled],
option:disabled {
  -webkit-appearance:none;
  opacity:0.7!important;
  cursor:not-allowed!important;
}


//Disabled Radio
input[type="radio"][disabled] ~ span {
  background-color:$colorAction;
  opacity:0.5;
}

input[type="radio"][disabled]:checked ~ span {
  background-color:$colorNeutral;
}



.step.invalid input[type="button"],
.refinance-exceeded .control-accept,
.repeater-item.error .control-accept {
  background-color:desaturate(lighten($colorAction, 20%), 20%);
  color:transparentize($colorNeutral, 0.3);
  cursor:not-allowed!important;
}

//Nested forms can result in a valid step inside invalid so above will render buttons invalid
.step.valid input[type="button"] {
  background-color:$colorAction;
  color:$colorNeutral;
  cursor:pointer!important;
}

select.disabled,
select[disabled] {
  background-image:none;
}

.slider-value {
  padding: 0 5px;
}


body::-webkit-scrollbar-thumb {
  color: $colorAction;
}





/***********************************************************************************************************************
*   7.  Validation Errors
************************************************************************************************************************/

.error > .form-input > label:before {
  margin:0 5px 0 0;
  display:inline-block;
  content:'!';
  width:20px;
  height:20px;

  line-height:20px;
  text-align:center;
  text-shadow:none;
  color:$colorNeutral;

  border-radius:50%;
  background-color:$colorError;
}

.error label.input-error {
  color:$colorError;
  font-weight:bold;
}

//Remove exclamation styling from checkboxes
.error > .form-input > label.checkbox:before,
.error > .form-input > label.checkbox ~ p {
  content:none;
  color:$colorError;
}



/* Validation Styling */
.error  > .form-input input,
.error  > .form-input select,
.error  > .form-input input[type="radio"] ,
.error  > .form-input input[type="checkbox"] ~ span {
  border:1px solid $colorError;
  background-color:mix(white, $colorError, 80%);
  z-index:999;
  color:$colorError;
}


//RANGE
.error  > .form-input input[type="range"] {
  border:none;
  background-color:transparent;
  border-left-color:$colorError;

}

.error  > .form-input input[type=range]::-webkit-slider-runnable-track {
  background: $colorError;
  border-left: 3px solid $colorError;
}

/* Mozilla */

.error  > .form-input input[type=range]::-moz-range-track {
  background:$colorError;
}

/* Internet Explorer */
.error  > .form-input input[type=range]::-ms-track {
  background: $colorError;
}

.error  > .form-input input[type=range]::-ms-fill-lower {
  background: $colorError;
}

.error  > .form-input input[type=range]:focus::-ms-fill-lower {
  background: $colorError;
}

.error  > .form-input input[type=range]::-webkit-slider-thumb {
  background: $colorError;
}

/* Mozilla */
.error  > .form-input input[type=range]::-moz-range-thumb {
  background: $colorError;
}

/* Internet Explorer */
.error  > .form-input input[type=range]::-ms-thumb {
  background: $colorError;
}


.error input[type="radio"] ~ span {
  border-color:$colorError;
  color:$colorError;
  background-color:mix(white, $colorError, 80%);

}

.error .date-suggestions {
  border-color:$colorError;
}

.error .date-suggestions input {
  border:none;
}




//Warning

.warning  > .form-input input,
.warning  > .form-input select,
.warning  > .form-input input[type="radio"] ,
.warning  > .form-input input[type="checkbox"] ~ span:before{
  border:1px solid $colorWarning;
  background-color:mix(white, $colorWarning, 80%);
  color:$colorWarning;
}


//RANGE
.warning  > .form-input input[type="range"] {
  border-left-color:$colorWarning;

}

.warning  > .form-input input[type=range]::-webkit-slider-runnable-track {
  background: $colorWarning;
  border-left: 3px solid $colorWarning;
}

/* Mozilla */

.warning  > .form-input input[type=range]::-moz-range-track {
  background:$colorWarning;
}

/* Internet Explorer */
.warning  > .form-input input[type=range]::-ms-track {
  background: $colorWarning;
}

.warning  > .form-input input[type=range]::-ms-fill-lower {
  background: $colorError;
}

.warning  > .form-input input[type=range]:focus::-ms-fill-lower {
  background: $colorWarning;
}

.warning  > .form-input input[type=range]::-webkit-slider-thumb {
  background: $colorWarning;
}

/* Mozilla */
.warning  > .form-input input[type=range]::-moz-range-thumb {
  background: $colorWarning;
}

/* Internet Explorer */
.warning  > .form-input input[type=range]::-ms-thumb {
  background: $colorWarning;
}


.warning input[type="radio"] ~ span {
  border:1px solid $colorWarning;
  color:$colorWarning;
  background-color:mix(white, $colorWarning, 80%);

}

.warning .date-suggestions {
  border-color: $colorWarning;
}




/***********************************************************************************************************************
*   4.  Native Input Styling
************************************************************************************************************************/

/***********************************************************
Numeric fields
***********************************************************/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

/***********************************************************************************************************************
*   4.  Switch Input Styling
************************************************************************************************************************/
.toggle-switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.toggle-switch-label .toggle-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.toggle-switch:checked + .toggle-switch-label .toggle-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.toggle-switch-label:active .toggle-switch-button {
  width: 60px;
}





/***********************************************************
Placeholders
***********************************************************/
input::-webkit-input-placeholder{
  color:transparentize($colorPrimary, 0.4);
}

select::-webkit-input-placeholder {
  color:transparentize($colorPrimary, 0.4);
}

input:-moz-placeholder{
  color:transparentize($colorPrimary, 0.4);
}
select:-moz-placeholder {
  color:transparentize($colorPrimary, 0.4);
}

input:-ms-input-placeholder{
  color:transparentize($colorPrimary, 0.4);
}
select:-ms-input-placeholder {
  color:transparentize($colorPrimary, 0.4);
}

input::placeholder{
  color:transparentize($colorPrimary, 0.4);
}
select::placeholder {
  color:transparentize($colorPrimary, 0.4);
}

input:disabled,
textarea:disabled,
option:disabled,
optgroup:disabled,
select:disabled:disabled {
  color:transparentize($colorPrimary, 0.4);
}
