//
.display-country-no,
.display-country-se,
.display-country-fi,
.display-country-dk {
  display:none;
}

.display-country-no {
  @if($countryCode == "NO") {
    display:block;
  }
}

.display-country-se {
  @if($countryCode == "SE") {
    display:block;
  }
}

.display-country-fi {
  @if($countryCode == "FI") {
    display:block;
  }
}

.display-country-da {
  @if($countryCode == "DA") {
    display:block;
  }
}


//Content marked hidden on all platforms
.no-display {
  display:none;
}