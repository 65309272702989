body,
html {
    color:$colorSolemn;
}


body,
body .site-body {
    font-size:1.6rem;
    font-family: "Helvetica Neue", "Verdana", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin:2rem 0 1rem 0;
    color: $colorSolemn;
}

h1, h2, h3 {
    text-align: center;
    font-weight:bold;
}


h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin-top:0;
}


h1 {
    font-size:4rem;
}

h2 {
    font-size:3rem;
}

h3 {
    font-size:2.4rem;
}

h4 {
    font-size:2rem;
}

h5 {
    font-size:1.2rem;
}


@media screen and (max-width:990px){
    h1 {
        font-size:3.2rem;
    }

    h2 {
        font-size:2.4rem;
    }

    h3 {
        font-size:2rem;
    }

    h4 {
        font-size:1.6rem;
    }

    h5 {
        font-size:1.2rem;
    }
}


@media screen and (max-width:550px){
    h1 {
        font-size:2.8rem;
    }

    h2 {
        font-size:2.2rem;
    }

    h3 {
        font-size:1.8rem;
    }

    h4 {
        font-size:1.6rem;
    }

    h5 {
        font-size:1.2rem;
    }

    body,
    body .site-body {
        font-size:1.6rem;
    }
}
