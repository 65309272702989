


/***********************************************************************************************************************
*   1.  Form Submitting Overlay
************************************************************************************************************************/

.overlay-loading {
  position:fixed;
  z-index:999;
  top:0;
  right:0;
  left:0;
  bottom:0;
  width:100vw;
  height:100vh;

  background-color:transparentize($colorAction, 0.1);
  color:$colorNeutral;
}

.overlay-container {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.overlay-heading h3 {
  margin-top:30px;
  text-align:center;
  font-size:2.6rem;
  font-weight:bold;
  color:$colorNeutral;
}

.overlay-heading .loading-spinner {
  margin:0 auto;
  display: block;
  position: relative;
  width: 94px;
  height: 94px;

}



/***********************************************************************************************************************
*   2.  Form Overlay States
************************************************************************************************************************/

.overlay-loading {
  display:none;
  animation-name:fadeOut;
  animation-duration:0.2s;
  animation-fill-mode: forwards;
}

.overlay-loading.active {
  display:block;
  animation-name:fadeIn;
  animation-duration:0.6s;
  animation-fill-mode: forwards;

}


@keyframes fadeIn {
  0% {
    display:block;
    opacity:0;
  }

  100% {
    display:block;
    opacity:1;
  }

}

@keyframes fadeOut {
  0% {
    display:block;
    opacity:1;
  }

  99% {
    display:block;
    opacity:0;
  }

  100% {
    display:none;
    opacity:0;
  }
}

/***********************************************************************************************************************
*   3.  Loading Spinner
************************************************************************************************************************/

.loading-wrapper {
  position:absolute;
  display:block;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
}

.loading-wrapper .loading-heading {
  margin-top:30px;
  text-align:center;
  font-size:2.6rem;
  font-weight:bold;
  color:$colorNeutral;
}

.loading-spinner {
  position:relative;
  display:block;
  margin:0 auto;
  width: 81px;
  height: 81px;
}

.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width:100%;
  height:100%;
  border: 4px solid $colorNeutral;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $colorNeutral transparent transparent transparent;
}
.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}


@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}