/***********************************************************************************************************************
*                             Styles for the Layout of the Page
                                  TABLE OF CONTENTS

*     1.    Structure
*     2.    Headers
*     3.    Navigation
*     4.    Footer
*     5.    Links
*     6.    Images
*     7.    Block Content
*     8.    Content Rows

************************************************************************************************************************/





/***********************************************************************************************************************
*                            PREFACE
*    Please add here only the styles related to the main layout. Also keep the styles clean and
*    update the table of content regularly.
************************************************************************************************************************/





/***********************************************************************************************************************
*   0.  Structure
************************************************************************************************************************/
body {
  background-color:$colorPrimary;
}

.body {
  overflow:hidden;
}

.page-section {
  padding:40px;
  background:$colorNeutral;
  margin-bottom:1px;
}

.product-page {
  margin-top:40px;
}



/***********************************************************************************************************************
*   1.  Headers
************************************************************************************************************************/

.site-header {
  position:absolute;
  width:100%;
}

.site-header .brand-logo {
  margin-top:10px;
  display:block;
}


.page-heading {
  margin:160px 0 40px 0;
}


.page-heading h1,
.page-heading h2,
.page-heading h3 {
  margin-top:0;
}

.page-heading .btn-secondary {
  color:$colorNeutral;
  border:1px solid $colorNeutral;
}

.page-heading .btn-secondary:hover,
.page-heading .btn-secondary:active {
  background-color:$colorNeutral;
  color:$colorAction;
}


@media screen and (max-width:540px) {
  .page-heading {
    margin:140px 0 20px 0;
  }
}



/***********************************************************************************************************************
*   2.  Navigation
************************************************************************************************************************/
.site-nav-menu ul {
  padding:40px 0;
  margin:0;
  text-align:center;
}

.site-nav-menu li {
  padding:30px 0;
}

.site-nav-menu li a {
  font-size:3.2rem;
  font-weight:bold;
  text-transform:uppercase;
}

.site-nav-menu li {
  transition: all ease-out 1s;
}

.site-nav-menu li:hover {
  background-color:rgba(0,0,0,0.2);
  transition: all ease-in 0.3s;
}

.site-nav-menu {
  position:fixed;
  right:0;
  bottom:0;
  left:0;
  top:-100%;
  width:100vw;
  height:100vh;

  background-color:$colorAction;
  opacity:0;

  z-index:-1;

  transition:opacity ease-out 0.2s;

}

.nav-active .site-nav-menu {
  z-index:99;
  opacity:1;
  top:0;

  transition:opacity ease-in 0.2s;
}

.site-nav-control {
  z-index:999;
}

.nav-control-icon {
  border:1px solid $colorNeutral;
  border-radius:50%;
}

.nav-control-icon span {
  background-color: $colorNeutral;
}


.site-nav .nav-container {
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
}


@media screen and (max-width:800px) {
  .site-nav-menu li a {
    font-size:2rem;
  }
}

@media screen and (max-width:550px) {
  .site-nav-menu li a {
    font-size:1.8rem;
  }
}


//Navigation icons

.site-nav-menu .nav-item {
  @include make-md-column(12);
  @include make-xs-column(12);

}
.site-nav-menu .nav-item-has-icon {
  @include make-sm-column(6);

  .nav-item-icon {
    display:block;
    max-width:150px;
    margin:0 auto;
  }
}

@media screen and (max-width:767px) {
  .site-nav-menu .nav-item-has-icon  .nav-item-icon {
    display:block;
    max-width:80px;
    margin:0 auto;
  }

  .site-nav-menu li {
    padding:15px 0;
  }

}






/***********************************************************************************************************************
*   3.  Content Rows
************************************************************************************************************************/

.content-rows {
  width:100%;
}

.content-row-products .product-rows .product-name {
  color:$colorNeutral;
}



/***********************************************************************************************************************
*   Footer
************************************************************************************************************************/






/***********************************************************************************************************************
*   4.  Color Classes
*
*   Can be used to add colors by class in the editor so CSS does not need modification. These classes should only
*   modify background and text color.
************************************************************************************************************************/


.background-transparent-overlay.background-transparent-overlay:before {
  position:absolute;
  content:'';
  top:0;
  right:0;
  bottom:0;
  left:0;

  background-color:$colorPrimary;
  opacity:0.3;
}





/***********************************************************************************************************************
*   5.  Lists
************************************************************************************************************************/

ol.large-bullets {
  list-style: none;
  counter-reset: li
}

ol.large-bullets li {
  font-size:2.2rem;
}

ol.large-bullets li::before {
  content: counter(li);
  counter-increment: li;

  display: inline-block;

  width:6rem;
  height:6rem;
  margin:15px 15px 15px 0;

  border-radius:50%;
  background:$colorNeutral;

  font-size:3rem;
  font-weight:bold;
  color:$colorContentBg2;
  text-align:center;
  line-height:6rem;
}






/***********************************************************************************************************************
*   6.  Horizontal Rules
************************************************************************************************************************/

hr {
  border-top: 1px solid $colorPrimary;
}





/***********************************************************************************************************************
*   7.  News Splash Refinancing
************************************************************************************************************************/

//News splash on offer repeater for refinacing
.repeater-offer .news-splash .form-description,
.repeater-offer .news-splash .form-description-label {
  display:none;
}

.repeater-offer .item-0 .news-splash .form-description {
  display:block;
  top:10px;
}

.repeater-offer .news-splash .form-description-content {
  padding:10px;
  margin-bottom:5px;
  background-color:$colorWarning;
  color:$colorNeutral;
  text-shadow:none;
  font-size:2rem;
}

.repeater-offer .news-splash .form-description-content:after {
  display: block;
  position:absolute;
  bottom:-18px;
  left: 10px;
  content:'';
  width: 10px;
  height: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-top: 10px solid $colorWarning;
}

//Animate the splash
.repeater-offer .news-splash {
  animation-name:bounceSmall;
  animation-iteration-count: infinite;
  animation-duration:1.5s;

  animation-delay: 2s;
}


@media screen and (max-width:767px) {
  .repeater-offer .news-splash .form-description {
    background-color:$colorWarning;
    color:$colorNeutral;
    padding:10px;
  }
}




.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}









/***********************************************************************************************************************
*   7.  Creditti pages
************************************************************************************************************************/

.left-custom-content .extendable-content-title {
  color:$colorNeutral;
  text-shadow:$textShadowHeadings;
}

//Creditti Score
.product-creditti-score .left-custom-content .extendable-content-title {
  text-shadow:none;
}





/***********************************************************************************************************************
*   8.  Loan Diploma Form
************************************************************************************************************************/

.step[data-step="applicationForm0"] .next-step-button {
  @extend .col-md-6;
  @extend .col-md-offset-3;
}





/***********************************************************************************************************************
*   9.  Loan Diploma Heading Content
************************************************************************************************************************/

.heading-content-loandiploma {
  overflow:hidden;

  .loandiploma-steps {
    @extend .row;
  }

  .loandiploma-step {
    @extend .col-md-4;
    padding:40px 0;
  }

}

@media screen and (min-width:1170px) {
  .top-custom-content {
    margin:100px 0 100px 0;
  }
}

@media screen and (min-width:960px) {
  .top-custom-content {
    margin:50px 0 50px 0;
  }
}


@media screen and (max-width:960px) {
  .loandiploma-steps {
    position:relative;
    width:300%;
    animation-name:slider3steps;
    animation-duration:10s;
    animation-fill-mode:both;
    animation-iteration-count: infinite;
  }

  .loandiploma-step {
    float:left;
    width:33%;
  }
}


@keyframes slider3steps {
  0% {
    left:0;
  }

  30% {
    left:0;
  }

  33% {
    left:-100%;
  }
  63% {
    left:-100%;
  }

  66% {
    left:-200%;
  }

  97% {
    left:-200%;
  }

  100% {
    left:0;
  }
}






/***********************************************************************************************************************
*   10.  Step Icons
************************************************************************************************************************/


.step-icon {
  margin:0 auto 20px auto;
  padding:20px;
  width:100px;
  height:100px;

  line-height:60px;
  font-size:6rem;
  text-align:center;
  color:$colorAction;
  background:$colorNeutral;

  border:1px solid $colorNeutral;
  border-radius:50%;
}

.step-icon i {
  line-height:60px;
  font-size:6rem;
  text-align:center;
  font-family:'Montserrat', sans-serif;
  font-style:normal;
  color:$colorAction;
}

.step-heading {
  margin:0;
  text-align:center;
  color:$colorNeutral;

  text-shadow:$textShadow;
}

.step-description {
  font-size:2rem;
  margin:0;
  text-align:center;
  font-weight:300;
  color:$colorNeutral;

  text-shadow:$textShadow;
}





/***********************************************************************************************************************
*   11.  Header USPs
************************************************************************************************************************/
.logo-usp-wrapper {
  display:none;
}

@media screen and (min-width:768px) {
  .logo-usp-wrapper {
    display:block;
    margin: 120px 0 40px;
  }

  .logo-usp-icon {
    margin: 0 auto 30px auto;
    width: 180px;
    height: 180px;

    line-height: 180px;
    text-align: center;
    border: 1px solid $colorNeutral;
    border-radius: 50%;
  }

  .logo-usp-icon .fas {
    line-height: 180px;
  }
}