

/***********************************************************************************************************************
*   Slider Overrides
************************************************************************************************************************/

.slider-wrapper:after {
  background-image:url('../images/creditti_logo_neg.svg');
  background-size:300px auto;
}


/* Hack to position slider relative to other slides */
.slider-wrapper.slider-type-panoramic .slider-layer .slider-item-content {
  padding-bottom:190px;
}


.slider-wrapper .slider-layer:first-child .slider-item:after,
.page-background:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgb(62,95,120);
  background: -moz-linear-gradient(0deg, $colorPrimary 0%, transparentize($colorPrimary, 0.5) 40%, transparentize($colorPrimary, 0.7) 100%);
  background: -webkit-linear-gradient(0deg, $colorPrimary 0%, transparentize($colorPrimary, 0.5) 40%, transparentize($colorPrimary, 0.7) 100%);
  background: linear-gradient(0deg, $colorPrimary 0%, transparentize($colorPrimary, 0.5) 40%, transparentize($colorPrimary, 0.7) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3e5f78",endColorstr="#3e5f78",GradientType=1);
}

.slider-layers {
  max-height:90vh;
}


.slider-item-content h1,
.slider-item-content h2,
.slider-item-content h3,
.slider-item-content h4,
.slider-item-content h5,
.slider-item-content h6 {
  color:$colorNeutral;
}

.slider-item-content h3 {
  font-size:2.2rem;
  font-weight:normal;
}

.slider-item-content h3,
.slider-item-content h4,
.slider-item-content h5,
.slider-item-content h6 {
  text-transform:none;
}



.slider-item-content .btn.btn-primary {
  width:auto;
  margin-top:3rem;
  background:$colorWarning;
}

@media screen and (max-width:360px) {
  .slider-layers {
    height:600px!important;
  }
}

@media screen and (max-width:500px) {
  //Offset background to compensate for image being narrow
  .slideshow-offset-background .slider-item-panoramic-bg {
    background-position: 60% center !important;
  }

  .slider-layers {
    height:650px!important;
  }

  .slider-item-content h3 {
    font-size:1.7rem;
  }

  .slider-item-content .btn.btn-primary {
    margin-top:1.5rem;
  }
}

@media screen and (max-height:900px) {
  .slider-wrapper.slider-type-panoramic .slider-layer .slider-item-content {
    padding-bottom:120px;
  }

}
@media screen and (max-height:700px) {
  .slider-wrapper.slider-type-panoramic .slider-layer .slider-item-content {
    padding-bottom:80px;
  }

}