/***********************************************************************************************************************
*                             Styles for FORM Inputs and Other Visuals Related to a Form
                                  TABLE OF CONTENTS

*     1.    Form Spacing
*     2.    General Stylings for Inputs
*     3.    Checkboxes and Radio Inputs
*     4.    Buttons
*     5.    Tooltips
*     6.    Placeholders
*     7.    Validation Errors
*     8.    Newsletter Form
*     9.    Debt Section
*     10.   Input Decorations
*     11.   Labels
*     12.   Labels

************************************************************************************************************************/
/***********************************************************************************************************************
*                            PREFACE
*      Please add here only the styles related to the rf-form-builder Inputs and how they look. Also keep the styles clean and
*    update the table of content regularly.
************************************************************************************************************************/




/***********************************************************************************************************************
*   1.  Form Spacing
************************************************************************************************************************/


.loanReturnInfo {
  padding-bottom:0;
}

.personalInfo {
  padding-top:0;
}




/***********************************************************************************************************************
*   2.  Form Sections
************************************************************************************************************************/





/***********************************************************************************************************************
*   2.  Special styles
************************************************************************************************************************/

.row.row-outline {
  padding:20px;
  border:1px solid $colorAction;
  border-bottom:none;
  margin-left:0;
  margin-right:0;
}

.row.row-outline:first-of-type {
  border-top-left-radius:15px;
  border-top-right-radius:15px;
}

.row.row-outline:last-of-type {
  border-bottom:1px solid $colorAction;
  border-bottom-left-radius:15px;
  border-bottom-right-radius:15px;
}



.row.row-outline > div:nth-child(2n+1) {
  clear:left;
}




/***********************************************************************************************************************
*   Progress Tracker
************************************************************************************************************************/

.progress-wrapper {
  @extend .container;
}

.progress-legend {
  display:none;
}

.progress-wrapper .progress-bar {
  width:100%;
  transition:width ease-in 1s;
}

.progress-wrapper[data-step="0"] .progress-bar {
  width:0 !important;
  transition:width ease-in 0.2s;
}






/***********************************************************************************************************************
*   Links in form
************************************************************************************************************************/

.product-form-wrapper a {
  color:lighten($colorAction, 25%);
}





/***********************************************************************************************************************
*   Form stuff
************************************************************************************************************************/

.slider-value {
  float:right;
  color: $colorSolemn;
}

.slider-label {
  padding-top:0px;
  margin-bottom:0;
}

.range-measurement-unit {
  margin-left:5px;
  font-size: 3rem;
}



.product-filter input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border:2px solid $colorNeutral;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  margin-top: -9px;
}

body::-webkit-scrollbar-thumb {
  color: $colorAction;
}

/* Mozilla */
.product-filter input[type=range]::-moz-range-thumb {
  border:2px solid $colorNeutral;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;

}

/* Internet Explorer */
.product-filter input[type=range]::-ms-thumb {
  border:2px solid $colorNeutral;
  height: 20px;
  width: 20px;
  background: $colorAction;
  cursor: pointer;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}


@media screen and (max-width:400px) {
  .resultpage .product-filter .slider-label,
  .resultpage .product-filter .slider-value {

  }
}



/***********************************************************************************************************************
*   4.  Content Under Form
************************************************************************************************************************/
.step-0 .below-form-content {
  @include make-md-column(6);
  font-size:1.4rem;
  clear:both;
}

.step-0 .below-form-content {
  display:block;
  @include make-md-column-offset(6);
  padding-top:20px;
  color:$colorNeutral;
}

.below-form-content {
  display:none;
}




/***********************************************************************************************************************
*   4.  Buttons
************************************************************************************************************************/

.product-form .btn,
.product-form input[type="button"] {
  background-color:$colorWarning;
}


.invalid .control-accept,
.step.invalid input[type="button"]  {
  background-color: desaturate(lighten($colorWarning, 20%), 20%);
}


.step.valid input[type="button"]  {
  background-color: $colorWarning;
}




/***********************************************************************************************************************
*   2.  Inputs
************************************************************************************************************************/
input,
input[type="text"],
input[type="textarea"],
input[type="email"],
input[type="number"],
select {
  border-radius:30px;
}


@media screen and (max-width:800px) {
  input,
  input[type="text"],
  input[type="textarea"],
  input[type="email"],
  input[type="number"],
  select {
    font-size:1em;
  }
}


@media screen and (max-width:800px) {
  input,
  input[type="text"],
  input[type="textarea"],
  input[type="email"],
  input[type="number"],
  select {
    border-radius:30px;
  }
}



/***********************************************************************************************************************
*   4.  Checkboxes
************************************************************************************************************************/

input[type="checkbox"] ~ span,
input[type="checkbox"] ~ span:before {
  border-radius:50%;
}




/***********************************************************************************************************************
*   5.  Radiobuttons
************************************************************************************************************************/


.radio-option:first-child input[type="radio"] ~ span {
  border-top-left-radius:30px;
  border-bottom-left-radius:30px;
}

.radio-option:last-child input[type="radio"] ~ span {
  border-top-right-radius:30px;
  border-bottom-right-radius:30px;
}






/***********************************************************************************************************************
*   6.  Date Selector
************************************************************************************************************************/


.date-suggestions input[type="text"] {
  border-radius:0;
}

.date-suggestions:first-child,
.date-suggestions:first-child input,
.date-suggestions:first-child select {
  border-top-left-radius:30px;
  border-bottom-left-radius:30px;
}

.date-suggestions:last-child,
.date-suggestions:last-child input,
.date-suggestions:last-child select {
  border-top-right-radius:30px;
  border-bottom-right-radius:30px;
}


.date-suggestions:first-child ul {
  border-left:1px solid $colorSolemn;
  border-bottom-left-radius: 30px;
}
.date-suggestions:last-child ul {
  border-right:1px solid $colorSolemn;
  border-bottom-right-radius: 30px;
}

.date-suggestions:first-child input.opened {
  border-radius: 0 !important;
  border-top-left-radius: 30px !important;
}

.date-suggestions:last-child input.opened {
  border-radius: 0 !important;
  border-top-right-radius: 30px !important;
}


@media screen and (max-width:800px) {
  .date-suggestions input,
  .date-suggestions select {
    line-height:$inputHeight * 0.9;
  }
}




/***********************************************************************************************************************
*   7.  Email Input
************************************************************************************************************************/

input[type="email"] {
  position:relative;
  z-index:99;
}

input[type="email"] ~ .email-suggestions {
  overflow:hidden;
  border-bottom-left-radius:30px;
  border-bottom-right-radius:30px;

  z-index:98;
}

.suggestions-visible input {
  border-bottom-left-radius:0;
  border-bottom-right-radius:0;
}

@media screen and (max-width:800px) {
  input[type="email"] ~ .email-suggestions {
    border-bottom-left-radius:25px;
    border-bottom-right-radius:25px;
  }
}




/***********************************************************************************************************************
*   8.  Custom Select
************************************************************************************************************************/

.custom-select.active input {
  border-radius: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

}

.custom-select.active .td-suggestions {
  border-radius: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}




/***********************************************************************************************************************
*   6.  Buttons
************************************************************************************************************************/
.btn-success,
.btn,
.btn-primary,
input[type="button"] {
  border-radius:30px;
}



/***********************************************************************************************************************
*   Content Block: Email Signup
************************************************************************************************************************/
.content-block-form.form-newsletter {
  .section {
    padding:0;
  }

  .section-heading {
    font-size:1.8rem;
    text-align:left;
    margin-bottom:0;
  }

  .form-input > label {
    display:none;
  }

  .form-input > label.checkbox {
    display:block;
  }

  input::placeholder {
    text-transform:uppercase;
    color:$colorPrimary;
  }

  input,
  .btn {
    line-height:5rem;
    font-size:1em;
  }

  .form-submit-successs {
    padding: 40px;
    border: 1px solid #fff;
    border-radius: 10px;
  }

  .next-step-button {
    margin:0;
    width:auto;
  }

  .submitting .form-window {
    opacity:0.15;
  }

  .submitting:before {
    display:block;
    position:absolute;
    width:100px;
    height:30px;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    margin-top:-1em;
    content:'';
    background-image:url(/assets/images/loading.svg);
    background-repeat:no-repeat;
    background-size:contain;
  }

  .submitting:after {
    opacity:1;
    content:'Submitting';
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%);

    font-family:'Montserrat', sans-serif;
    font-size:2rem;
    text-transform:uppercase;
    color:$colorAction;
  }

}




/***********************************************************************************************************************
*   Site Footer: Email Signup
************************************************************************************************************************/

.site-footer .form-newsletter {
  @include make-sm-column(10);
  @include make-sm-column-offset(2);



  input,
  .btn {
    color:$colorNeutral;
  }

  input.btn {
    border:none;
    width:100%;
  }

  input {
    background-color:transparent;
    border:1px solid $colorNeutral;
  }

}






/***********************************************************************************************************************
*   7.  Repeater
************************************************************************************************************************/

.repeater-item:first-child {
  border-top-left-radius:30px;
  border-top-right-radius:30px;
}

.repeater-item:last-child {
  border-bottom-left-radius:30px;
  border-bottom-right-radius:30px;
}


.repeater-item-control.control-accept {
  border-radius:30px;
}





/***********************************************************************************************************************
*   7.  Refinance Validator
************************************************************************************************************************/

.refinance-validator {
  display:block;
  width:50%;
  background:#fff;
  margin:0 auto 30px auto;
  padding:15px 30px;
  border:1px solid $colorPrimary;
  border-radius:30px;

  text-align:center;
}

.refinance-validator.refinance-exceeded .refinance-validator-heading:before {
  font-family:'Fontawesome';
  content:'\f06a';
  display:inline-block;
  margin-right:5px;
}


.refinance-validator-datalist:after {
  content:'';
  clear:both;
  display:table;
}

.refinance-validator-datalist dt,
.refinance-validator-datalist dd {
  display:inline-block;
}

.refinance-validator-datalist dt {
  float:left;
  clear:left;
}

.refinance-validator-datalist dd {
  float:right;
  clear:right;
}

.refinance-validator-datalist .factors:after ,
.refinance-validator-datalist .result:after {
  display:table;
  content:'';
  clear:both;
}

.refinance-validator .refinance-amount,
.refinance-validator .refinance-loan-amount {
  font-weight:bold;
}


//Error in refinance - amount too high
.refinance-exceeded .refinance-validator {
  border:1px solid $colorError;
  //background-color:transparentize($colorWarning, 0.6);
  color:$colorError;
}

.refinance-exceeded .refinance-validator .refinance-validator-heading {
  color:$colorError;
}

.refinance-exceeded .refinance-validator dd.invalid {
  font-weight:bold;
}


@media screen and (max-width:767px) {
  .refinance-validator {
    width:100%;
    bottom:0;
    cursor:pointer;
  }

  .step.active .refinance-validator {
    position:fixed;
    left:0;
    right:0;
    margin:0;
    z-index:999;

    border:none;
    border-radius:0;
    background-color:$colorPrimary;
    color:$colorNeutral;
  }

  .step.active .refinance-validator .result {
    border-color:$colorNeutral;
  }

  .step.active .refinance-validator .refinance-validator-heading {
    color:$colorNeutral;
  }

  .step.active .refinance-exceeded .refinance-validator {
    background-color:$colorError;
  }

  .step.active .refinance-validator.hidden {
    bottom:50px;
    transform:translateY(100%);

    transition:all ease-out 0.4s;
  }

  .step.active .refinance-validator.visible {
    bottom:0;

    transition:all ease-in 0.3s;
  }

  .step.active .refinance-validator .refinance-validator-heading:after {
    font-family:'Fontawesome';
    content:'\f106';
    display:inline-block;
    margin-left:5px;
    transition:all ease-out 0.2s;
  }

  .step.active .refinance-validator.visible .refinance-validator-heading:after {
    transform:rotate(180deg);
    transition:all ease-in 0.2s;
  }

}




/***********************************************************************************************************************
*   8.  Labels
************************************************************************************************************************/

label,
.field-description,
.form-description-content,
label.checkbox ~ p {
  color: $colorNeutral;
  text-shadow: 0px 0px 2px rgba(21,44,61,.55),0px -1px 2px rgba(21,44,61,.45),0px -1px 4px rgba(21,44,61,.35),0 -1px 8px rgba(21,44,61,.35);
}


//Remove shadow from radio buttons
label.radio-option span {
  text-shadow:none;
}

//Smaller checkbox labels
label.checkbox ~ p {
  font-size:1.35rem;
}


  /***********************************************************************************************************************
  *   12.  Wrappers
  ************************************************************************************************************************/

.monthly-payment-container {
  width:100%;
  overflow:hidden;
}

.monthly-payment-label {
  margin:0;
  font-size: 2.8rem;
  font-weight: bold;
  color: $colorSolemn;
  float:left;
  transform: translateY(60%);
}

.monthly-payment-value {
  font-size: 5rem;
  font-weight: bold;
  color: $colorSolemn;
  float: right;

  .monthlyPayCurrency {
    font-size: 2.8rem;
  }

}

.monthly-payment-example {
  clear:both;
}