.scroller-component.scroll-to-form {
  position: fixed;
  bottom:20px;
  right:20px;
  z-index:999;
  left: auto;
}

.scroll-to-form .trigger{
  background: $colorAction;
  border-radius:50%;
  width:50px;
  height:50px;

  text-align:center;
  line-height:50px;

  color:$colorNeutral;
  cursor:pointer;
  box-shadow:0 3px 10px rgba(0,0,0,0.4);
}

.scroll-to-form a {
  display:none;
}

.scroll-to-form .trigger:after {
  content:'\f062';
  font-family: "Font Awesome\ 5 Pro";
  font-size:2rem;
  color:$colorNeutral;
}