
/***********************************************************************************************************************
*   Result Display - General
************************************************************************************************************************/

$borderValues: 1px solid transparentize($colorAction, 0.8);

@mixin hardwareTransform {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  //Fix potential flickering in Webkit:
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}


//Loan Slider Settings
.resultpage .product-filter .slider-label,
.resultpage .product-filter .slider-value,
.resultpage .form-description-content,
.resultpage .field-description,
.resultpage .range-input label,
.resultpage .loan-details label,
.resultpage .product-filter label {
  text-shadow:none;
  color:$colorPrimary;
}

.resultpage .product-filter .slider-label,
.resultpage .product-filter .slider-value,
.resultpage .product-filter .slider-value .range-measurement-unit {
  font-family:'Montserrrat', sans-serif;
  font-size:2.4rem;
  font-weight:bold;
}

@media screen and (max-width:550px) {
  .resultpage .product-filter .slider-label,
  .resultpage .product-filter .slider-value,
  .resultpage .product-filter .slider-value .range-measurement-unit {
    font-size:2rem;
  }
}

@media screen and (max-width:400px) {
  .resultpage .product-filter .slider-label,
  .resultpage .product-filter .slider-value,
  .resultpage .product-filter .slider-value .range-measurement-unit {
    font-size:1.8rem;
  }
}

.resultpage-content {
  border-radius:20px;
  background:#fff;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.75);

  margin-bottom:60px;
  padding:0;
}

.page-section {
  padding:0;
  background:none;
}

.result-row {
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}

.result-row:after {
  clear:both;
  display:table;
  content:'';
}

.result-row:first-child,
.result-row:first-child .result-column {
  border-top:none;
}

.result-column {
  max-width:100%;
  flex:1 0 auto;

  padding:40px;
  border-left:1px solid $colorPrimary;
  border-top:1px solid $colorPrimary;
}

.result-column:first-child {
  border-left:none;
}

.result-display,
.result-value {
  font-family:"Montserrat", sans-serif;
}

.result-text {
  font-family:"Open Sans", sans-serif;
}

.result-text h1,
.result-text h2,
.result-text h3,
.result-text h4,
.result-text h5,
.result-text h6 {
  text-align:left;
  font-family:"Montserrat", sans-serif;
}

.result-label {
  margin:0;
  font-size:16px;
  text-align:right;
  text-transform:uppercase;
}

.result-value {
  font-size:4.5rem;
  font-weight:bold;
  text-align:right;
  color:$colorAction;
}


.result-info-link .hover-trigger {
  font-weight:normal;
  font-size:1.2rem;
  text-transform:uppercase;
  font-family:"Montserrat", sans-serif;
  line-height:4rem;
}


.result-actions {
  text-align:right;
  //margin-top:30px;
}

.result-actions:after {
  display:table;
  clear:both;
  content:"";
}


.result-actions .result-info-link {
  line-height:4rem;
}

.result-actions .result-info-button .btn,
.result-actions .btn {
  width:auto;
  margin-left:20px;
  line-height:4rem;
  background-color:$colorWarning;
}


.result-row.warning{
  background-color:$colorWarning;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
}

.result-row.warning .result-column {
  padding:20px 40px;
}

.result-row.warning h4 {
  margin:0;
  color:$colorNeutral;
  text-align:center;
}


@media screen and (max-width:767px) {
  .result-column {
    padding:20px;
  }
  .result-row.warning .result-column {
    padding:10px 20px;
  }

}




/***********************************************************************************************************************
*   Score + Factor
************************************************************************************************************************/

.product-creditscore,
.page-section.resultpage-content {
  background-color:$colorNeutral;
}

.product-creditscore {
  padding:40px;
}

.creditscore-actions .btn-primary {
  background-color:$colorWarning;
  width:auto;
}

@media screen and (max-width:767px) {
  .product-creditscore {
    padding: 40px;
  }
}



/***********************************************************************************************************************
*   Home Finance
************************************************************************************************************************/

.product-home-finance .result-label:after {
  clear:both;
  display:table;
  content:'';
}

.product-home-finance .result-label {
  line-height:20px;
}

.product-home-finance .result-label .result-value {
  float:right;
}



.product-home-finance .result-image {
  position:relative;
  text-align:center;
}

.product-home-finance .result-image .result-value {
  position:absolute;
  bottom:20%;
  left:50%;

  transform:translateX(-50%);
}



.product-home-finance .result-info-link .hover-trigger {
  line-height:4rem;
}

.product-home-finance .result-actions .result-info-link,
.product-home-finance .result-actions .result-info-button {
  display:inline-block;
  vertical-align:top;
}

.product-home-finance .hover-content-component.position-bottom .hover-content:before {
  right:auto;
}


.product-home-finance .hover-content-component.position-bottom .hover-content {
  min-width:300px;
}



//Client Logos
.partner-logos {
  padding:15px 40px;
  text-transform:uppercase;
  text-align:center;
}

.partner-logos span {
  margin:0 5px;
}

.partner-logos .partner-logo {
  display:inline-block;
}

.partner-logos .partner-logo img {
  width:100px;
}

.partner-logos .finansdanmark-logo img {
  width:200px;
}



@media screen and (max-width:990px) {
  .product-home-finance .result-label {
    line-height:45px;
  }


  .product-home-finance .result-image {
    margin-bottom:30px;
  }

}


@media screen and (max-width:767px) {
  .product-home-finance .result-actions .result-info-link,
  .product-home-finance .result-actions .result-info-button {
    display: block;
    text-align: center;
  }
}


@media screen and (max-width:600px) {
  .product-home-finance .result-label {
    line-height:3rem;
  }
  .product-home-finance .result-label .result-value {
    font-size:3rem;
  }
  .product-home-finance .result-label .result-value,
  .product-home-finance .result-label {
    display:block;
    text-align:right;
    float:none;
  }

  .product-home-finance .result-graph {
  }

  .product-home-finance .result-graph:after {
    content:'';
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    width:40px;
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;

    background: -moz-linear-gradient(90deg, rgba(46,34,59,0) 0%, rgba(64,49,79,0.1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(46,34,59,0) 0%, rgba(64,49,79,0.1) 100%);
    background: linear-gradient(90deg, rgba(46,34,59,0) 0%, rgba(64,49,79,0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e223b",endColorstr="#40314f",GradientType=1);
  }

  .product-home-finance .result-graph-container {
    overflow-x:scroll;
  }

  .product-home-finance .result-graph-container > div {
    min-width:500px;
  }

  .product-home-finance .result-image img {
    max-width:160px;
  }
}




/***********************************************************************************************************************
*   Google Maps Result
************************************************************************************************************************/

//Ensure maps has a min-height in tablet and mobile
@media screen and (max-width:990px) {
  .result-value.google-maps {
    min-height:400px;
  }
}




/***********************************************************************************************************************
*   Tab Bar
************************************************************************************************************************/

.tab-bar .tab-bar-item-label {
  text-shadow:none;
  color:$colorPrimary;
}


@media screen and (max-width:990px) and (min-width:768px) {
  @include tabsRowSize(4, 15rem, true);
}





/***********************************************************************************************************************
*   Filter - Range Slider
************************************************************************************************************************/
.product-filter,
.product-filter .section {
  padding:0;
}

.product-filter h2,
.product-counter-wrapper {
  display:none;
}



.product-filter .loan-settings {
  @include make-lg-column(9);
  @include make-md-column(8);
  @include make-xs-column(12);
  border-right:$borderValues;
}

.product-filter .loan-details {
  @include make-lg-column(3);
  @include make-md-column(4);
  @include make-xs-column(12);
}

.product-filter .range-input {
  @include make-xs-column(12);
}

.product-filter .slider-label,
.product-filter .slider-value {
  font-family:'Montserrat', sans-serif;
  font-weight:bold;
}
.product-filter .interestRate label,
.product-filter .duration label {
  display:inline-block;
}

.product-filter .interestRate .form-description-content,
.product-filter .duration .form-description-content {
  display:block;
  font-size:2.4rem;
  font-weight:bold;
  text-align:right;
  color:$colorAction;

}



@media screen and (min-width:992px) {
  .product-filter .section {
    display:table;
  }

  .product-filter .loan-details,
  .product-filter .loan-settings {
    float:none;
    display:table-cell;
    margin:0;
    padding:30px;
  }
  .product-filter .loan-settings {
    border-right:$borderValues;
  }
}


@media screen and (max-width:991px) {

  .product-filter .loan-settings {
    margin:0;
    border-bottom:$borderValues;
    border-right:none;
  }

  .product-filter .loan-details {
    margin:0;
  }
}




/***********************************************************************************************************************
*   Result Display - Creditti score
************************************************************************************************************************/

.creditscore-wrapper {
  width:100%x;
  height:240px;
  max-height:100%;
  margin-bottom:40px;
}

.creditscore-body {
  display:inline-block;
  width:100%;
  height:100%;
  background:url(/assets/images/creditscore/creditscore_badge.svg);
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center;
}

// Actions
.creditscore-actions {
  margin-top:40px;
  text-align:center;
}

.creditscore-actions .btn.btn {
  //width:48%;
  //margin-right:4%;
}

.creditscore-actions .btn:last-child {
  margin-right:0;
}

.creditscore-value {
  display:inline-block;
  width:100%;

  font-family:'Montserrat', sans-serif;
  font-size:5em;
  line-height:1em;
  font-weight:bold;
  text-align:center;
  margin-top:85px;

  background: rgba(20,44,61,1);
  background: -moz-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(190,22,34,1)), color-stop(15%, rgba(20,44,61,1)), color-stop(65%, rgba(20,44,61,1)), color-stop(100%, rgba(51,153,153,1)));
  background: -webkit-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -o-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -ms-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: linear-gradient(to bottom, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be1622', endColorstr='#339999', GradientType=0 );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.creditscore-unit {
  font-size:0.5em; //Percentage of value's size

  background: rgba(20,44,61,1);
  background: -moz-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(190,22,34,1)), color-stop(15%, rgba(20,44,61,1)), color-stop(65%, rgba(20,44,61,1)), color-stop(100%, rgba(51,153,153,1)));
  background: -webkit-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -o-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: -ms-linear-gradient(top, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  background: linear-gradient(to bottom, rgba(190,22,34,1) 0%, rgba(20,44,61,1) 15%, rgba(20,44,61,1) 55%, rgba(51,153,153,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be1622', endColorstr='#339999', GradientType=0 );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.credit-score-text h1,
.credit-score-text h2,
.credit-score-text h3,
.credit-score-text h4,
.credit-score-text h5,
.credit-score-text h6 {
  text-align:left;
}


.HomePage .creditscore-value {
  font-size:6em;
}



//Creditscore colors
.creditscore-value.credit-score-F,
.credit-score-text.credit-score-F  h2,
.creditscore-value.credit-score-F .creditscore-unit,
.creditscore-value.credit-score-E,
.credit-score-text.credit-score-E  h2,
.creditscore-value.credit-score-E .creditscore-unit {
  background: -moz-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, $colorWarning 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(20,44,61,1)), color-stop(35%, rgba(20,44,61,1)), color-stop(100%, $colorWarning));
  background: -webkit-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, $colorWarning 100%);
  background: -o-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, $colorWarning 100%);
  background: -ms-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, $colorWarning 100%);
  background: linear-gradient(to bottom, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, $colorWarning 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be1622', endColorstr='$colorWarning', GradientType=0 );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.creditscore-value.credit-score-A,
.credit-score-text.credit-score-A h2,
.creditscore-value.credit-score-A .creditscore-unit,
.creditscore-value.credit-score-B,
.credit-score-text.credit-score-B h2,
.creditscore-value.credit-score-B .creditscore-unit,
.creditscore-value.credit-score-C,
.credit-score-text.credit-score-C h2,
.creditscore-value.credit-score-C .creditscore-unit  {
  background: -moz-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, rgba(51,153,153,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(15%, rgba(20,44,61,1)), color-stop(35%, rgba(20,44,61,1)), color-stop(100%, rgba(51,153,153,1)));
  background: -webkit-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, rgba(51,153,153,1) 100%);
  background: -o-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, rgba(51,153,153,1) 100%);
  background: -ms-linear-gradient(top, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, rgba(51,153,153,1) 100%);
  background: linear-gradient(to bottom, rgba(20,44,61,1) 0%, rgba(20,44,61,1) 35%, rgba(51,153,153,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#be1622', endColorstr='#339999', GradientType=0 );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}




@media screen and (max-width:990px) {
  .creditscore-actions .btn.btn {
    width:100%;
    margin-right:0;
    margin-bottom:20px;
  }
}





/***********************************************************************************************************************
*   Result Display - Loan Diploma
************************************************************************************************************************/

.product-creditti-factor {

  .result-row {
    display:flex;
  }

  .result-column {
    width:60%;
    padding:0;
    display: flex;
    flex-direction: column;
    flex:1;
    align-items:stretch;
    align-content:stretch;
    border-right: $borderValues;
    border-bottom: $borderValues;
  }

  .result-column:last-child {
    border-right:none;
  }

  .result-grade {
    position:relative;
    z-index:15;
  }
  .result-maximum-loan-amount {
    position:relative;
    z-index:10;
  }
  .result-monthly-payment {
    position:relative;
    z-index:1;
  }

  .result-graphed {
    flex:1;
  }

  .result-maximum-loan-amount,
  .result-monthly-payment {
    width:100%;
    height:50%;
  }

  .result-graphed .result-graph {
    @include make-sm-column(5);
    @include make-xs-column(12);
  }

  .result-graphed .result-graph-content {
    @include make-sm-column(7);
    @include make-xs-column(12);
  }


  .calculation-results {
    @include make-row();
    padding:0;
  }

  .result-display.result-display {
    padding:30px;
    border-bottom:$borderValues;

    transition:all ease-out 1s;
  }

  .result-display.result-display:last-child {
    border-bottom:none;
  }

  .result-display:hover {
    background-color:transparentize($colorAction, 0.92);
    transition:all ease-in 0.5s;
  }

  .result-display:last-child {
    border-right:none;
  }


  .result-heading {
    font-size: 1.7rem;
    text-align: right;
    margin-bottom: 0.5em;
  }

  .result-heading:after {
    display:table;
    content:'';
    clear:both;
  }

  .result-graphed .result-heading {
    text-align: left;
  }


  .result-graph {
    margin-bottom: 20px;
  }

  .result-score {
    font-family:'Montserrat', sans-serif;
    font-size: 4.5rem;
    font-weight: bold;
    text-align: right;
    line-height: 1em;
    color: $colorAction;
  }

  .result-score .result-score-unit {
    font-size: 2.25rem;
  }

  .result-heading .result-score {
    font-size:2.5rem;
    float:right;
    line-height:1.7rem;
  }

  .result-information {
    display: block;
    text-transform: uppercase;
    text-decoration: underline;
    text-align: right;
  }

  .result-information .hover-content {
    text-transform: none;
    font-size: 1.7rem;
  }


  .graph-circle-bar .result-score {
    text-align: center;
  }

  .result-score .graph-circle-bar .result-score {
    font-size: 8rem;
  }


  .scroll-to-offers{
    text-align:center;
  }

  .scroll-to-offers .btn {
    width:auto;
    margin:0 auto;

    background:$colorWarning;
  }



  @media screen and (max-width:990px) {

    .result-row {
      display:block;
    }

    .result-column {
      display:block;
      width:100%;
    }
  }



  @media screen and (max-width:767px) {
    .result-section .result-display {
      display:block;
    }

    .result-display .result-graph {
      float:none;
      max-width:300px;
      margin:0 auto 30px auto;
    }
  }


  @media screen and (max-width:540px) {
    .result-display.result-display {
      padding:20px;
    }
    .result-display .result-graph {
      max-width:200px;
    }
  }

  @media screen and (max-width:400px) {
    .result-heading {
      font-size:1.6rem;
    }
  }
}







/***********************************************************************************************************************
*   Graphs
************************************************************************************************************************/

.result-graph {
  position:relative;
}

.graph-circle-bar .result-score {
  width:100%;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);

  padding:12px;

  font-size:10rem;
}


//Score coloring
.score-a .graph-circle-bar .CircularProgressbar-path,
.score-b .graph-circle-bar .CircularProgressbar-path,
.score-c .graph-circle-bar .CircularProgressbar-path {
  stroke:$colorAction;
}

.score-a .graph-circle-bar .result-score,
.score-b .graph-circle-bar .result-score,
.score-c .graph-circle-bar .result-score {
  color:$colorAction;
}


.score-d .graph-circle-bar .CircularProgressbar-path,
.score-e .graph-circle-bar .CircularProgressbar-path {
  stroke:$colorWarning;
}

.score-d .graph-circle-bar .result-score,
.score-e .graph-circle-bar .result-score {
  color:$colorWarning;
}

.score-f .graph-circle-bar .CircularProgressbar-path {
  stroke:$colorError;
}

.score-f .graph-circle-bar .result-score {
  color:$colorError;
}

.graph-circle-bar .CircularProgressbar-trail {
  color:#d6d6d6;
}



/***********************************************************************************************************************
*   Calculation Extendable Content
************************************************************************************************************************/

.result-calculation-details {
}

.result-calculation-details .extendable-content-nav {
  text-align:center;
  z-index:9;
}

.result-calculation-details .extendable-content-nav h4 {
  @extend .btn;
  @extend .btn-secondary;

  width:auto;
  font-size:1.7rem;
}

.result-calculation-details .active .extendable-content-nav h4 {
  background:$colorAction;
  border:1px solid #fff;
  color:#fff;
}

.result-calculation-details .extendable-content-nav h4:before {
  content:'\f0eb';
  margin:0 12px 0 0;
  color:$colorAction;
  font-family:'Font Awesome\ 5 Pro';
  font-size:2.2rem;
  font-weight:900;

  transition:color ease-out 0.2s;
}

.result-calculation-details .active .extendable-content-nav h4:before,
.result-calculation-details .extendable-content-nav h4:hover:before {
  color:$colorNeutral;

  transition:color ease-in 0.2s;
}

.result-calculation-details .active .extendable-content-nav:before {
  content:'';
  position:absolute;
  bottom:-15px;
  left:50%;
  transform:translateX(-50%);
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;

  border-top: 16px solid $colorNeutral;
}

.result-calculation-details .active .extendable-content-nav:after {
  content:'';
  position:absolute;
  bottom:-14px;
  left:50%;
  transform:translateX(-50%);
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;

  border-top: 15px solid $colorAction;
}



.result-calculation-details .extendable-content-wrapper {
  z-index:1;

  margin:-33px 0 33px 0;
  padding:0;
}

.result-calculation-details .extendable-content-content {
  padding:80px 40px 40px 40px;
  color:$colorNeutral;
}


.result-excerpt-conclusion {
  padding:40px;
}


.result-calculation-details .extendable-content-content h1,
.result-calculation-details .extendable-content-content h2,
.result-calculation-details .extendable-content-content h3,
.result-calculation-details .extendable-content-content h4,
.result-calculation-details .extendable-content-content h5,
.result-calculation-details .extendable-content-content h6,
.result-calculation-details .extendable-content-content p {
  color:$colorNeutral;
}

.result-calculation-details .extendable-content-wrapper.active {
  background-color:$colorAction;
  color:$colorNeutral;

  overflow:visible;
  max-height:none;

  transition:all ease-in-out 0.3s;
}



/* Conclusions Content */
.result-extended-conclusion {
  column-count: 2;
}

.result-excerpt-conclusion h1,
.result-excerpt-conclusion h2,
.result-excerpt-conclusion h3,
.result-extended-conclusion h1,
.result-extended-conclusion h2,
.result-extended-conclusion h3 {
  column-span:all;
}


.results-extended-data {
  margin-top:30px;
  padding-top:30px;

  border-top:1px solid #fff;
}

.results-extended-data.data-list {
  column-count:2;
}

.results-extended-data .data-label {
  font-weight: bold;
}


@media screen and (max-width:767px) {
  .result-excerpt-conclusion,
  .results-extended-data.data-list,
  .result-extended-conclusion {
    column-count: 1;
  }

  .result-calculation-details .extendable-content-content {
    padding:60px 20px 20px 20px;
  }


  .result-excerpt-conclusion {
    padding:20px;
  }


  .result-calculation-details .extendable-content-nav span:before {
    font-size:1.8rem;
  }
}