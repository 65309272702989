$borderValues: 1px solid transparentize($colorAction, 0.8);

/* Arrow left */
$arrowLeftColorNeutral: $colorNeutral;
$imgArrowLeft: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.75 31.4'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:#{$arrowLeftColorNeutral};stroke-width:2px;%7D%3C/style%3E%3C/defs%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath id='Rectangle_15_copy_6' data-name='Rectangle 15 copy 6' class='cls-1' d='M16,30.7,1.4,15.7,16,.7'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E";


.resultpage-offers {
    margin-top: 40px;
}


/***********************************************************************************************************************
*   Tab bar
************************************************************************************************************************/
.tab-bar {
    margin: 20px 0;
}

.tab-bar .active .tab-bar-item-label {
    color: #fff;
}


//When only one offer-category-item exists, hide the tab
.offer-category-filter .offer-category-item:only-child {
    display: none;
}


/***********************************************************************************************************************
*   Splashes General
*
*   NOTE: The default offer-splash class is used on all offers across the app
************************************************************************************************************************/
.offer-splash {
    position: absolute;
    max-width: 150px;
    height: auto;
    max-height: 150px;

    top: 0;
    left: 0;
    transform: translate(-30%, -30%) rotate(-15deg);
}

@media screen and (max-width: 989px) {
    .offer-splash {
        max-width: 100px;
        max-height: 100px;
    }
}

@media screen and (max-width: 500px) {
    .offer-splash {
        max-width: 80px;
        max-height: 80px;
    }
}


/***********************************************************************************************************************
*   Result Page Offers General
************************************************************************************************************************/
.offer-wrapper:after {
    content: '';
    display: table;
    clear: both;
}

.offer-category-filter,
.offer-wrapper {
    //@include make-row;
}

.product-offers {
    height: 100%;
}

.resultpage-offers {
    //@extend .row;
    margin-bottom: 60px;
}

//@TODO refactor this - really dump to nest two identical classes like this
.resultpage-offers .resultpage-offers {
    @extend .row;
}

.resultpage-offers .product-offers {
    @extend .container;
}


.offer-container {
    margin-bottom: 20px;
}

//.offer-category-filter,
.product-filter {
    display: none;
}

.product-offer {
    position: relative;
    background-color: $colorNeutral;
    border-radius: 10px;
    box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, .75);
}


.product-offer-inner,
.product-offer-loanexample {
    padding: 20px 20px 10px 20px;
    display: block;
    width: 100%;
}

.offer-client-logo {
    margin-bottom: 20px;
}

.product-offer-action {
    margin-top: 20px;
}

.product-offer-action .btn {
    padding: 15px 30px;
    line-height: 1em;
}

.advertisement-information {
    text-align: center;
    padding: 10px 0 0 0;
    color: $colorPrimary;
}

.product-offer-loanexample {
    opacity: 0.6;
}

.offer-client-logo img {
    display: block;
    max-height: 50px;
    max-width: 75%;
    margin: 0 auto;
}

.product-offer-title {
    text-align: center;
}


/* Product Offer USPs */
.product-offer-usp {
    margin: 20px 0;
}

.product-offer-usp-list {
    list-style: none;
    margin: 10px 0;
    padding: 0;
}

.product-offer-usp-list li {
    position: relative;
    padding: 5px 5px 5px 40px;
}

.product-offer-usp-list li:before {
    width: 30px;
    height: 30px;
    content: '\f00c';
    font-family: 'Font Awesome\ 5 Pro';
    display: block;
    position: absolute;
    left: 0;
    color: $colorAction;
    border: 1px solid $colorAction;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
}

/* Product Offer Details */
.product-offer-details {
    margin: 20px 0;
}

.product-offer-details-list {
    width: 100%;
    display: table;
    table-layout: fixed;
    list-style: none;
    padding: 0;
    border-bottom: 1px dotted transparentize($colorPrimary, 0.6);

}

.product-offer-details-list li {
    display: table-row;
    border-top: 1px dotted transparentize($colorPrimary, 0.6);
}

.product-offer-details-list .offer-detail-label {
    display: table-cell;
    padding: 5px;
    border-top: 1px dotted transparentize($colorPrimary, 0.6);
}

.product-offer-details-list .offer-detail-value {
    display: table-cell;
    font-weight: bold;
    padding: 5px;
    font-size: 1.2em;
    text-align: right;
    border-top: 1px dotted transparentize($colorPrimary, 0.6);
}


@media screen and (max-width: 767px) {
    .product-offer-action .btn.btn {
        padding: 15px 30px;
    }
}

@media screen and (min-width: 960px) {
    .product-offer-inner {
        padding: 40px 40px 20px 40px;
        display: block;
        width: 100%;
    }

    /* Product Offer Details */

    .product-offer-details {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .product-offer-details-list {
        display: table-row;
        border: none;
    }

    .product-offer-details-list li {
        display: table-cell;
        border: none;
    }

    .product-offer-details-list .offer-detail-label {
        display: block;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
        border: none;
    }

    .product-offer-details-list .offer-detail-value {
        display: block;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
        font-size: 1.2em;
        border: none;
    }

    /* Product Offer USPs */
    .product-offer-usp {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .product-offer-usp-list {
        display: table-row;
    }

    .product-offer-usp-list li {
        display: table-cell;
    }

}


/***********************************************************************************************************************
*   External Offers Expanding Box
*
*   Collapses offers and lets users click to expand them to full height on mobile
************************************************************************************************************************/

.product-offer-external.collapsing {

    /*
     * DESKTOP DISPLAY
     */

    .product-offer-content-expanding .product-offer-content-wrapper {
        padding: 40px;
    }

    .product-offer-content-trigger {
        color: $colorAction;
    }

    &.product-offer:hover .product-offer-content-trigger {
        cursor: pointer;
    }

    .product-offer-content-trigger .link {
        text-transform: uppercase;
        text-align: center;
        line-height: $inputHeight;
        vertical-align: middle;

        animation-name: bounceSmall;
        animation-duration: 1s;

        border-top: 1px solid $colorAction;
    }

    .product-offer-content-trigger .link:before {
        display: inline-block;
        margin-right: 5px;
        content: '+';
        //font-size: 1.5em;
        line-height: $inputHeight;
        vertical-align: middle;
    }

    .active .product-offer-content-trigger .link:before {
        content: '-';
    }

    .product-offer-content-expanding .product-offer-extended-content {
        max-height: 0;
        overflow: hidden;

        transition: all ease-in 0.2s
    }

    .product-offer-content-expanding.active .product-offer-extended-content {
        height: auto;
        max-height: 400px;

        transition: all ease-out 0.2s

    }

    /*
     * MOBILE DISPLAY
     */
    @media (hover: hover) {
        .product-offer-content-trigger {
            //max-height:0;
            overflow: hidden;

            transition: max-height ease-out 0.4s;
        }

        &.product-offer:hover .product-offer-content-trigger {
            height: auto;
            max-height: 60px;

            transition-delay: 0.3s;
            transition: max-height ease-in 0.2s;
        }
    }


    @media screen and (max-width: 959px) {
        .product-offer-inner .product-offer-content {
            position: relative;
            max-height: 120px;
            overflow: hidden;
            padding-bottom: 40px;

            transition: all ease-in 0.3s;
        }

        .product-offer-inner .product-offer-content.active {
            padding-bottom: 0;
            max-height: 650px;
            height: auto;

            transition: all ease-out 0.3s;
        }


        .product-offer-inner .product-offer-content:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;

            background: rgb(255, 255, 255);
            background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%);
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
        }

        .product-offer-inner .product-offer-content.active:after {
            background: none;
        }

        .product-offer-inner .product-offer-content:before {
            display: block;
            content: '...';
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 10;

            font-size: 4.5rem;
            text-align: center;
            color: $colorAction;

            opacity: 1;

            transition: all ease-in 0.2s;
        }

        .product-offer-inner .product-offer-content.active:before {
            opacity: 0;
            transition: all ease-out 0.2s;
        }

        .product-offer-extended-content {
            display: block;
        }


        .product-offer-content-expanding {
            display: none;
        }
    }
}


/***********************************************************************************************************************
*   Internal Offers
*
*   Displays internal offers that contain an application flow in the offer.
************************************************************************************************************************/

.resultpage-offers .product-offer.product-offer-internal {
    .product-offer-content-wrapper {
        @extend .row;
    }

    .product-offer-content {
        @extend .col-xs-12;
        display: block;
    }

    .product-offer-application .consent-field {
        @include make-sm-column(6);
        @include make-xs-column(12);
    }

    .product-offer-application {
        position: relative;
        width: 100%;
    }

    .product-offer-application label,
    .product-offer-application label ~ p {
        color: $colorPrimary;
        text-shadow: none;
    }

    .product-offer-content-trigger {
        display: none;
    }


    //Offer title and logo
    .product-offer-title {
        display: block;
        max-width: none;
    }

    .offer-client-logo img {
        max-height: 75px;
        max-width: 250px;
    }
}


//Active Application
.resultpage-offers .offer-container.active {
    .product-offer-application-control {
        display: block;
    }

    &:after {
        opacity: 1;
        z-index: 9998;
        transition: opacity ease-out 1s;
    }

    .product-offer-application {
        width: auto;
        margin: 0 -40px;
        padding: 40px;
        background: $colorNeutral;
        z-index: 9999;
    }

    //Application Controls

    .product-offer-application-control {
        position: absolute;
        top: -20px;
        right: -20px;

        z-index: 10002;

        width: 45px;
        height: 45px;

        background: $colorAction;
        background-image: url($imgIconClose);
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        border-radius: 50%;

        cursor: pointer;
    }


    @media screen and (max-width: 959px) {
        .product-offer-application,
        .product-offer-title.product-offer-title {
            position: fixed;
            padding: 15px;
            max-width: 1170px;
            z-index: 10000;

            background: $colorNeutral;
            left: 15px;
            right: 15px;
        }

        .product-offer-title.product-offer-title {
            padding-bottom: 0;
        }


        //Pop-out application
        .product-offer-application {
            top: 30px;
            bottom: 15px;

            //max-height: 90%;
            overflow-y: auto;
            overflow-x: visible;

            margin: auto;
            padding: 15px;
        }

        //Navigation
        .sectionNav {
            position: fixed;
            left: 15px;
            right: 15px;
            bottom: 14px;
            z-index: 10000;
        }
        .nav-section.row {
            margin: 0;
        }

        .sectionNav .next-step-button {
            padding: 0;
            width: 80%;
        }

        .sectionNav .previous-step-button {
            padding: 0;
            width: 20%;
        }


        //Remove back nav from step-1 as we should only allow closing popup at this step
        .step-1 .sectionNav .next-step-button {
            width: 100%;
        }

        .step-1 .sectionNav .previous-step-button {
            display: none;
        }

        .sectionNav .btn {
            border-radius: 0;
            padding: 0;
            width: 100%;
        }

        .sectionNav .nav-previous {
            display: block;
            float: none;
            width: 100%;
            height: $inputHeight * 0.9;
            padding: 0;
            margin: 0;
            left: 0;
            background: $colorAction;


            background-image: url($imgArrowLeft);
            background-position: center;
            background-size: auto 25px;
            background-repeat: no-repeat;
        }

        .sectionNav .nav-previous:before {
            background-position: center;
            height: $inputHeight;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        //First Step
        .step-0 .sectionNav .next-step-button {
            padding: 0;
            width: 100%;

        }

        .step-0 .sectionNav .previous-step-button {
            display: none;
        }

        .sectionNav .previous-step-button a {
            display: none;
        }

        .product-offer-application-control {
            position: fixed;
            top: 30px;
            right: 30px;
        }

    }

}

/***********************************************************************************************************************
*   Result Page Offers Display Format
*
*   Display offers in either a horizontal format or a X-width grid
************************************************************************************************************************/

.resultpage-offers .display-grid {

    .section .row [class^=col-] {
        @include make-xs-column(12);
        @include make-sm-column(12);
    }


    @media screen and (min-width: 768px) {
        .offer-wrapper {
            min-height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }

        .offer-container {
            display: flex;
            flex-basis: 49.9%; //Safari Hack
            justify-content: center;
            flex-direction: column;
            margin: 0;
        }

        .offer-container:nth-child(2n) .product-offer {
            margin-right: 0;
        }

        .product-offer {
            margin: 0 20px 20px 0;
            height: 100%;
        }

        .product-offer-inner,
        .product-offer-loanexample {
            padding: 40px 40px 20px 40px;
            display: block;
            width: 100%;
        }
        .product-offer-loanexample {
            padding-top: 0;
        }
    }

    /* Clear elements so margins work */
    .product-offer-content:after,
    .product-offer-action:after {
        display:table;
        content:"";
        clear:both;
    }
}


.resultpage-offers .display-list {
    .product-offers.display-list {
        .product-offer-content h1,
        .product-offer-content h2,
        .product-offer-content h3,
        .product-offer-content h4,
        .product-offer-content h5,
        .product-offer-content h6 {
            margin-bottom: 10px;
            text-align: left;
        }
    }


    @media screen and (min-width: 959px) {
        .offer-wrapper {
            min-height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
        }

        .offer-container {
            display: block;
            flex-basis: 100%;
            justify-content: center;
            flex-direction: column;
        }

        .product-offer-inner {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }

        .offer-client-logo,
        .product-offer-content,
        .product-offer-action {
            margin: 0;
            padding: 0 20px;
            display: flex;
            flex-direction: column;

            justify-content: center;
        }

        .offer-client-logo {
            padding-left: 0;
            flex: 1;
            align-items: center;
            max-width: 140px;
        }

        .product-offer-title {
            width: 100%;
        }

        .product-offer-content {
            flex: 5;
        }
        .product-offer-action {
            padding-right: 0;
            flex: 2;
            align-items: center;
            max-width: 240px;
        }

    }

    /* Clear elements so margins work */
    .product-offer-content:after,
    .product-offer-action:after {
        display:table;
        content:"";
        clear:both;
    }
}


/***********************************************************************************************************************
*   Featured Offers Section
************************************************************************************************************************/

.featured-product-offers {
    margin: 40px 0 0 0;
}

$featuredBannerColor: $colorWarning;
$featuredBannerImg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='99.875' height='99.875' viewBox='0 0 99.875 99.875'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill:#{$featuredBannerColor}; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M0,0H99.865L0,99.865V0Z'/%3E%3C/svg%3E%0A";

.featured-product-offers .product-offer {
    position: relative;
    background-image: url($featuredBannerImg);
    background-repeat: no-repeat;
    background-position: top left;
    border: 5px solid $colorWarning;

    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;

        content: '\f521';
        color: $colorNeutral;
        font-family: 'Font Awesome\ 5 Pro';
        font-size: 3rem;
        font-weight: 900;
        line-height: 50px;
        text-align: center;
    }

    .product-offer-action {
        margin-top: 20px;
        padding: 0;
        max-width: 100%;
    }


    //Remove the collapsed content from featured offers
    .product-offer-inner .product-offer-content {
        max-height: none;
        overflow: visible;
        padding: 0;
    }

    .product-offer-inner .product-offer-content:after,
    .product-offer-inner .product-offer-content:before {
        content: none;
    }
}


/***********************************************************************************************************************
*   Application Information Box
*************************************************************************************************************************/

.application-information .user-data-wrapper {
    overflow: hidden;
    height: 0;
    max-height: 0;

    transition: max-height ease-out 0.6s;
}

.application-information[data-display="true"] .user-data-wrapper {
    height: auto;
    max-height: 2500px;

    transition: max-height ease-in 0.6s;
}
