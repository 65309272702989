
/***********************************************************************************************************************
*   4.  Footer
************************************************************************************************************************/

.site-footer {
    position: relative;
    width: 100%;
    background:$colorBackground;
    bottom: 0;
    min-height:40vh;

    padding:40px 0;
}
