/***********************************************************************************************************************
*                             Styles for Creditti Home form and pages
*
*     This stylesheet contains all styling for the Interstitial Offer type.
*
*     NOTE: This should be moved to sub-modules when feature is completed for version 1.0
*
************************************************************************************************************************/

.product-offers-interstitial {
  padding:0;
  margin:40px 0;
}
.product-offers-interstitial.has-active-offers {
  @extend .container;
  position:absolute;
  z-index:10000;
  top:180px;
}


.offers-wrapper-interstitial {
  position:relative;
  border-radius: 30px;
  box-shadow: 5px 15px 30px 0 rgba(0, 0, 0, 0.75);
  background-color:$colorNeutral;
}

.offer-interstitial-wrapper {
  position:relative;
}

.offer-interstitial-content {
  @extend .row;
  padding:40px;
}

.offer-interstitial-content:after {
  clear:both;
  display:table;
  content:"";
}

.offer-interstitial-content .offer-interstitial-header {
  @include make-xs-column(12);
  margin-bottom:60px;
}

.offer-interstitial-form {
  padding:0 40px 40px 40px;
  border-top:1px solid transparentize($colorPrimary, 0.4);
}

.offer-interstitial-form .section {
  padding-bottom:0;
}

.offer-interstitial .step,
.offer-interstitial .step.active {
  margin:0;
}

.offer-interstitial .field-description,
.offer-interstitial .form-description-content,
.offer-interstitial label,
.offer-interstitial label.checkbox ~ p {
  text-shadow:none;
  color:$colorPrimary;
}


.offer-interstitial-content .client-logo {
  text-align: center;
}

.offer-interstitial-content .client-logo img {
  max-width:240px;
  max-height:140px;
}



@media screen and (max-width:989px) {
  .offer-interstitial-content .client-content {

  }
}


@media screen and (max-width:767px) {
  .product-offers-interstitial {
  }

  .offer-interstitial-content .offer-interstitial-header {
    margin-top:30px;
  }

  .offer-interstitial-content {
    padding:20px;
  }

  .offer-interstitial-form {
    padding:0 20px 20px 20px;
  }
}



/***********************************************************************************************************************
*   Severity Bar
*************************************************************************************************************************/

$sevCol1:#A92133;
$sevCol2:#e77122;
$sevCol3:#F1AC26;
$sevCol4:#BDC523;
$sevCol5:#30A37B;
$sevCol6:#14707A;


.severity-indicator-wrapper {
  margin-top:60px;
}

.severity-indicator-heading {
  text-transform:uppercase;
  font-size:1.8rem;
  text-align:center;
}

.severity-indicator {
  position:relative;
}

.severity-indicator-bar {
  line-height:1rem;
}

.severity-indicator-value {
  width:30px;
  height:30px;
  line-height:26px;
  background-color:$colorPrimary;
  border-radius:50%;
  border:2px solid $colorNeutral;

  color:$colorNeutral;
  text-align:center;
  font-weight:bold;

  top:-10px;
  margin-left:-15px; //Center the 30px indicator
}

[class^="severity-indicator-bar-"] {
  display:inline-block;
  height:10px;
  border-right:1px solid #fff;
}

[class^="severity-indicator-bar-"]:last-child {
  border-right:0;
}

.severity-indicator-bar-0 {
  background-color:$sevCol1;
}
.severity-indicator-bar-1 {
  background-color:$sevCol2;
}
.severity-indicator-bar-2 {
  background-color:$sevCol3;
}
.severity-indicator-bar-3 {
  background-color:$sevCol4;
}
.severity-indicator-bar-4 {
  background-color:$sevCol5;
}
.severity-indicator-bar-5 {
  background-color:$sevCol6;
}





/***********************************************************************************************************************
*   Counter
*************************************************************************************************************************/

.product-offers-interstitial .product-counter-wrapper {
  display: inline-block;
  position: absolute;
  top: -30px;
  left:50%;
  transform:translateX(-50%);
  z-index: 999;
}

.product-offers-interstitial .product-counter-wrapper {
  display:block;
}

.product-offers-interstitial .product-counter {
  display:inline-block;

  width:60px;
  height:60px;

  font-family:'Montserrat', sans-serif;
  text-align:center;
  line-height:6rem;
  font-size:2rem;

  border-radius:50%;

  background-color:$colorAction;
  color:$colorNeutral;
}

.product-offers-interstitial .product-counter a {
  color:$colorNeutral;
}





/***********************************************************************************************************************
*   Progress Bar
*************************************************************************************************************************/

.interstitial-progress-wrapper {
  margin:0 30px 30px 20px;
  position:relative;
}

.interstitial-progress-wrapper .progress-bar {
  width:100%;
  border-bottom-color:transparent;
}

.interstitial-progress-wrapper .step-wrapper:after,
.interstitial-progress-wrapper .step-has-sub-steps:after {
  display:table;
  clear:both;
  content:'';
}


/*******************************************************
*   Step Labels
*******************************************************/
.interstitial-progress-wrapper .step-label {
  position:absolute;
  top:50%;
  left:30px;
  transform:translateY(-50%);
  color: $colorNeutral;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}



/*******************************************************
*   Steps
*******************************************************/
.interstitial-progress-wrapper .step,
.interstitial-progress-wrapper .sub-step {
  position:relative;
  height:40px;
}

.interstitial-progress-wrapper .step {
  z-index:99;
}

.interstitial-progress-wrapper .sub-step {
  height:20px;
}

.interstitial-progress-wrapper .step-has-sub-steps {
  height:auto;
}



/*******************************************************
*   Step Dots - attached to the label
*******************************************************/
.interstitial-progress-wrapper .step-label:after {
  position: absolute;
  display: block;
  content: '';
  width:20px;
  height:20px;
  border-radius:50%;
  background-color:$colorNeutral;

  z-index: 99;
}

.interstitial-progress-wrapper .active .step-label:after {
  background-color:$colorAction;
  border:2px solid $colorNeutral;

  width:22px;
  height:22px;
}

.interstitial-progress-wrapper .completed .step-label:after {
  background-color:$colorAction;
  border:2px solid $colorNeutral;
}

.interstitial-progress-wrapper .step-has-sub-steps > .step-label:after {
  display:none;
}





/*******************************************************
*   Progress Line
*******************************************************/

@media screen and (max-width:767px) {
  .interstitial-progress-wrapper {
    margin:0 auto 30px auto;
    width:70%;
  }

  .interstitial-progress-wrapper .step-label:after {
    top:50%;
    left: -30px;
    transform:translateY(-50%);
    width:15px;
    height:15px;
  }

  .interstitial-progress-wrapper .active .step-label:after {
    left:-33px;
  }


  /* Works as the progress bar as we can style each segment by step number */
  .interstitial-progress-wrapper .step:before,
  .interstitial-progress-wrapper .sub-step:before,
  .interstitial-progress-wrapper .step:after,
  .interstitial-progress-wrapper .sub-step:after {
    content: '';
    position: absolute;
    left:7px;
    height: 50%;
    border-left: 1px solid #fff;
    z-index: -1;
  }

  .interstitial-progress-wrapper .step:before,
  .interstitial-progress-wrapper .sub-step:before {
    bottom: 50%;
  }

  .interstitial-progress-wrapper .step:after,
  .interstitial-progress-wrapper .sub-step:after {
    top: 50%;
  }

  .interstitial-progress-wrapper .step.completed:before,
  .interstitial-progress-wrapper .step.completed:after,
  .interstitial-progress-wrapper .sub-step.completed:before,
  .interstitial-progress-wrapper .sub-step.completed:after,
  .interstitial-progress-wrapper .active:before {
    border-left: 3px solid $colorNeutral;
    left:6px;
  }
}

//No border before first and after last, step
.interstitial-progress-wrapper .step:first-child:before,
.interstitial-progress-wrapper .step:last-child:after {
  border: none !important;
}



//DISPLAY ON TABLET+ SIZE

@media screen and (min-width:768px) {

  .interstitial-progress-wrapper {
    margin:30px 0 90px;
  }


  /*******************************************************
  *   Step Wrapper
  *******************************************************/
  .interstitial-progress-wrapper .step-wrapper {
    position:absolute;
    width:100%;
    top:50%;
    transform:translateY(-50%);
  }


  /*******************************************************
  *   Steps
  *******************************************************/
  .interstitial-progress-wrapper .step,
  .interstitial-progress-wrapper .sub-step {
    position: relative;
    float: left;
    min-height: 1px;
  }


  /*******************************************************
  *   Step Labels
  *******************************************************/
  .interstitial-progress-wrapper .step-label {
    position: absolute;
    width: 100%;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }


  /*******************************************************
  *   Step Dots - attached to the label
  *******************************************************/
  .interstitial-progress-wrapper .step-label:after {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .interstitial-progress-wrapper .step-has-sub-steps > .step-label:after {
    display: none;
  }

  .interstitial-progress-wrapper .active .step-label:after {
    background-color: $colorAction;
    border: 2px solid $colorNeutral;

    width: 30px;
    height: 30px;
  }


  /*******************************************************
  *   Progress Line
  *******************************************************/

  /* Works as the progress bar as we can style each segment by step number */
  .interstitial-progress-wrapper .step:before,
  .interstitial-progress-wrapper .sub-step:before,
  .interstitial-progress-wrapper .step:after,
  .interstitial-progress-wrapper .sub-step:after {
    content: '';
    position: absolute;
    width: 50%;
    border-bottom: 1px solid #fff;
    z-index: -1;
  }

  .interstitial-progress-wrapper .step:before,
  .interstitial-progress-wrapper .sub-step:before {
    right: 50%;
  }

  .interstitial-progress-wrapper .step:after,
  .interstitial-progress-wrapper .sub-step:after {
    left: 50%;
  }

  .interstitial-progress-wrapper .step.completed:before,
  .interstitial-progress-wrapper .step.completed:after,
  .interstitial-progress-wrapper .sub-step.completed:before,
  .interstitial-progress-wrapper .sub-step.completed:after,
  .interstitial-progress-wrapper .active:before {
    border-bottom: 3px solid $colorNeutral;
  }

}




/***********************************************************************************************************************
*   Load/Unload Offers
*************************************************************************************************************************/
.offer-interstitial:first-child {

}

.offer-interstitial {
  position:absolute;
  top:0;
  left:0;
  opacity:0;
  z-index:-1;
}

.offer-interstitial.active {
  position:relative;
  animation-name:fadeIn;
  animation-fill-mode:forwards;
  animation-duration:0.4s;
  animation-delay:0.4s;
  z-index:1;
}





/***********************************************************************************************************************
*   Actions
*************************************************************************************************************************/
.offer-interstitial .nav-section {
  text-align:center;
}

.offer-interstitial .nav-section .next-step-button,
.offer-interstitial .nav-section .custom-button {
  display:inline-block;
  vertical-align:top;
}

.offer-interstitial .nav-section .next-step-button {
  margin:0;
  padding:0;
  width:auto;
  float:none;
}

.offer-interstitial .nav-section .next-step-button .btn {
  background-color:$colorAction;
  color:$colorNeutral;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
}

.offer-interstitial .nav-section .custom-button {
  width:auto;
}

.offer-interstitial .nav-section .custom-button a {
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  position:relative;
  color:$colorAction;
  background-color:transparent;
  border:1px solid $colorAction;
}

.offer-interstitial .invalid .nav-section .next-step-button input[type="button"] {
  background-color:desaturate(lighten($colorAction, 20%), 20%);
  color:transparentize($colorNeutral, 0.3);
}


@media screen and (max-width:600px) {
  .offer-interstitial .nav-section .custom-button,
  .offer-interstitial .nav-section .next-step-button {
    width:100%;
    padding:0 15px;
  }

  .offer-interstitial .nav-section .custom-button a {
    display:block;
    border-radius:30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .offer-interstitial .nav-section .next-step-button input[type="button"] {
    display:block;

    border-radius:30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}





/***********************************************************************************************************************
*   USP List
*************************************************************************************************************************/
.offer-interstitial-content .client-usps {
  text-align:center;
}

.offer-interstitial-content .usp-list {
  display:inline-block;
  list-style:none;
  margin:40px 0 20px 0;
  text-align:left;
}

.offer-interstitial-content .usp-list li {
  position:relative;
  margin:15px 0;

  font-weight:bold;
}

.offer-interstitial-content .usp-list li:before {
  position:absolute;
  top:50%;
  left:-30px;
  transform:translateY(-50%);
  display:block;
  content:'';
  width:24px;
  height:24px;

  background:url($imgCheckmark);
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center;
}





/***********************************************************************************************************************
*   Submitting
*************************************************************************************************************************/

.submitting .offer-interstitial-content,
.submitting .offer-interstitial-form {
  opacity:0.5;
}

.offer-interstitial .loading-spinner {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  z-index:9999;
}

.offer-interstitial .loading-spinner div {
  border-color: $colorAction transparent transparent transparent;
}

.offer-interstitial .submitting:before {
  content:"";
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}