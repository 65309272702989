/***********************************************************************************************************************
*                             Styles for the Layout of the Page
                                  TABLE OF CONTENTS


*     1.    General Typography
*     2.    Page Typography
*     3.    Section Typography
*     4.    Form Typography and Shadows
*     5.    MENU Typography
*     6.    Content Row Typography
*     7.    FOOTER Typography

************************************************************************************************************************/
/***********************************************************************************************************************
*                            PREFACE
*      Please add here only the styles related to the rf-form-builder Inputs and how they look. Also keep the styles clean and
*    update the table of content regularly.
************************************************************************************************************************/

/***********************************************************************************************************************
*   1.  General Typography
************************************************************************************************************************/
body,
html {
  color:$colorSolemn;
}


body,
body .site-body {
  font-size:1.7rem;
  font-family: "Open Sans", sans-serif;
  font-weight:300;
}


h1, h2, h3 {
  text-align: center;
  font-weight:bold;
  text-transform:uppercase;
}

h1, h2, h3, h4, h5, h6 {
  font-family:"Montserrat", sans-serif;
  margin:2rem 0 1rem 0;
  color: $colorSolemn;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin:0 0 2rem 0;
}


h1 {
  font-size:4rem;
}

h2 {
  font-size:3rem;
}

h3 {
  font-size:2.4rem;
}

h4 {
  font-size:2rem;
}

h5 {
  font-size:1.4rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top:0;
}


@media screen and (max-width:990px){
  h1 {
    font-size:3.2rem;
  }

  h2 {
    font-size:2.4rem;
  }

  h3 {
    font-size:2.1rem;
  }

  h4 {
    font-size:1.6rem;
  }

  h5 {
    font-size:1.2rem;
  }
}


@media screen and (max-width:800px) {
  body,
  body .site-body {
    font-size:1.5rem;
  }
}


@media screen and (max-width:550px){
  h1 {
    font-size:2.4rem;
  }

  h2 {
    font-size:2rem;
  }

  h3 {
    font-size:1.7rem;
  }

  h4 {
    font-size:1.5rem;
  }

  h5 {
    font-size:1.2rem;
  }

  body,
  body .site-body {
    font-size:1.6rem;
  }
}





/***********************************************************************************************************************
*   2.  Page Typography
************************************************************************************************************************/

.page-heading{
  color:$colorNeutral;
}

.page-heading h1,
.page-heading h2,
.page-heading h3,
.page-heading h4,
.page-heading h5,
.page-heading h6 {
  text-align:center;
  text-shadow:$textShadowHeadings;
  color:$colorNeutral;
}

.page-heading h1,
.modal-component-header h1 {
  line-height:1em;
}

.modal-component-header h1 {
  line-height:1em;
}

.modal-component-header,
.modal-component-header h1,
.modal-component-header h2,
.modal-component-header h3,
.modal-component-header h4,
.modal-component-header h5 {
  overflow-wrap: break-word;
}


/***********************************************************************************************************************
*   3.  Section Typography
************************************************************************************************************************/


.section h2 {
  text-align:center;
  text-transform: uppercase;
  color:$colorNeutral;
  text-shadow:$textShadow;
}

.loan-example {
  clear:both;
  margin:20px 0 0 0;
  font-style:italic;
  color:$colorContentBg1;
}




/***********************************************************************************************************************
*   4.  Form Typography and Shadows
************************************************************************************************************************/

.left-custom-content h1{
  color: $colorWarning;

  font-size: 7rem;
  line-height:1em;
}

label {
  font-weight:300;
}

label, .slider-value {
  text-transform: uppercase;
}

.slider-label, label.checkbox {
  opacity:1;
}

.slider-value,
.slider-label,
.slider-value .range-measurement-unit {
  font-size:3rem;
}

.slider-value {
  font-weight: bold;
}

@media screen and (max-width:1200px) {
  .slider-value,
  .slider-label,
  .slider-value .range-measurement-unit {
    font-size:2.4rem;
  }
}

@media screen and (max-width:990px) {
  .slider-value,
  .slider-label,
  .slider-value .range-measurement-unit {
    font-size:2rem;
  }
}

@media screen and (max-width:767px) {
  .slider-value,
  .slider-label,
  .slider-value .range-measurement-unit {
    font-size:2.4rem;
  }
}

@media screen and (max-width:420px) {
  .slider-value,
  .slider-label,
  .slider-value .range-measurement-unit {
    font-size:2rem;
  }
}





/***********************************************************************************************************************
*   5.  MENU Typography
************************************************************************************************************************/

.nav-menu {
  text-transform: uppercase;
  font-size: 1.8rem;
}





/***********************************************************************************************************************
*   6. Content Row Typography
************************************************************************************************************************/
.content-rows.rejected-offers,
#bottomContent .content-row,
.footer .content-row {

  color:$colorNeutral;

  h1,h2,h3,h4,h5,h6 {
    color:$colorNeutral;
  }
}

.content-rows.rejected-offers,
#bottomContent .content-row,
.footer .content-row {
  h3,h4,h5,h6{
    text-align:left;
  }
}





/***********************************************************************************************************************
*   7. FOOTER Typography
************************************************************************************************************************/

.site-footer,
.site-footer .content-row,
.site-footer p,
.site-footer h1,
.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5,
.site-footer h6,
.site-footer li {
  color: $colorNeutral;
}