/***********************************************************************************************************************
*   Modal
*
* Renders content in a fixed position popover box. Used across the application for
* various purposes.
************************************************************************************************************************/


.modal-component {
  opacity: 0;
  height:0;
  overflow:hidden;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity  0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;

}

.modal-component.active {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000;
  max-height: 85%;
  max-width:800px;
  width:90%;
  height:auto;
  background: $colorAction;
  color: $colorNeutral;
  opacity: 1;
  overflow-y:auto;

  box-shadow:0 0 5px rgba(0,0,0,0.4), 0 5px 20px rgba(0,0,0,0.25), 0 10px 40px rgba(0,0,0,0.2);
}

.modal-component-header,
.modal-component-content {
  h1, h2, h3, h4, h5, h6,a {
    color: $colorNeutral;
  }
  a {
    text-decoration:underline;
  }
  p {
    color: $colorNeutral;
  }
}

.modal-component-header {
  h1, h2, h3, h4, h5, h6 {
    bottom:0;
  }
}

.modal-component-header,
.modal-component-content {
  padding:25px 50px 50px 50px;
}

.modal-component-close {
  min-height: 57px;
  margin: 20px 0;
}

.modal-component-close .icon {
    position: absolute;
    left: 50%;
    transform: translateX(- 50%);
    width:57px;
    height:57px;
    background-color: $colorNeutral;
    border-radius:50%;

}

.modal-component-close .icon:after {
    width:100%;
    height:100%;
    display:block;
    content:"";
    background: url($imgIconClose) no-repeat 50%;
    background-size:50%;
}


[data-modal-source]{
  cursor:pointer;
}



@media screen and (max-width:600px) {
  .modal-component-content,
  .modal-component-header {
    padding:20px 25px 25px;
  }

  .modal-component-header.modal-component-header {
    h1, h2, h3, h4, h5, h6 {
      margin:0;
    }
  }
}


/***********************************************************************************************************************
*   2.  Modal Types
************************************************************************************************************************/




/***********************************************************************************************************************
*   3.  Background shield
************************************************************************************************************************/

.background-shield {
  z-index: 99999;
  background: $colorSolemn;
  opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}





/***********************************************************************************************************************
*   4.  Modal Checkboxes
*
*   Used for signup offer checkboxes that render in the modal.
************************************************************************************************************************/

.modal-checkbox-advertiser {
  margin-right:10px;
  font-weight:bold;
}
