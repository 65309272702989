/***********************************************************************************************************************
*                             Styles for le React Component Biblioteque
                                  TABLE OF CONTENTS

*     4.    Modal
*     6.    Scroller
*     7.    Logo List
*     8.    Product Offers

************************************************************************************************************************/



/***********************************************************************************************************************
*   4.  Modal
************************************************************************************************************************/


.modal-component .modal-component-header,
.modal-component .modal-component-content {
  p,
  label.checkbox ~ p {
    color: $colorNeutral;
  }
}



/***********************************************************************************************************************
*   6.  Scroller
************************************************************************************************************************/

.scrollerComponent div {
  cursor: pointer;
}


.scrollerComponent {
  position:absolute;
  left:50%;
  transform:translateX(-50%);
  bottom:60px;
}

.scroll-down.nav-link {
  float:none;
  left:50%;
  transform:translateX(-50%);
}

.scroll-down.nav-link:before {
  transform:rotateZ(-90deg) translateY(-50%);
  left:50%;
  margin-left:8px;
  top:auto;
  bottom:-10px;
}





/***********************************************************************************************************************
*   7. Logo List
************************************************************************************************************************/

ul.list-logos {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align:right;
}

ul.list-logos li {
  display: inline-block;
}

ul.list-logos img {
  width: auto;
  height: 3em;
  padding: 10px;
}


/***********************************************************************************************************************
*   8.    Product Offers
************************************************************************************************************************/

.product-offer-short {
  background: $colorNeutral;
  position: relative;
  .product-offer-title {
    text-align: center;
  }
  .product-offer-spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: $colorPrimary;
    opacity: 0.5;
    height: 100%;
    width: 100%;
  }
}

.product-offer-short.submitted {
  background: $colorAction;
  .product-offer-success-message {
    text-align: center;
  }
}





/***********************************************************************************************************************
*   9.    User Data List
************************************************************************************************************************/

.user-data-list {
  padding:40px 20px;
  border:1px solid $colorPrimary;
  border-radius:25px;
}
