
/***********************************************************************************************************************
*   1.   Table
************************************************************************************************************************/
table {
  width:100%;
}

td {
  padding:5px 10px;
}

tr {
  border-bottom:1px solid transparentize($colorNeutral, 0.85);
}

tr:first-child {
  border-top:1px solid transparentize($colorNeutral, 0.85);
}

td:first-child {
  border-right:1px solid transparentize($colorNeutral, 0.85);
}




/***********************************************************************************************************************
*   2.   Definition List
************************************************************************************************************************/

dl {
  display: table;
  width:100%;
}

dl >  .data-list-item {
  display:table-row;
}

dl >  .data-list-item:nth-child(2n) {
  background-color:transparentize($colorPrimary, 0.95);
}

dt,
dd {
  display:table-cell;
}

dt {
  font-weight:normal;
  padding-left:15px;
}

dd {
  font-weight:bold;
  padding:8px 0;
  padding-right:15px;
}

@media screen and (max-width:600px) {
  dt,
  dd {
    display:block;
    padding-left:12px;
    padding-right:12px;
  }
  dt {
    padding-top:5px;
  }
  dd {
    font-weight:bold;
    padding-bottom:5px;
  }
}




/***********************************************************************************************************************
*   3. Data List
************************************************************************************************************************/

.data-wrapper {

}

.data-controls {
  margin:10px 0;
  text-align:center;
}

.data-controls .control-toggle {
  line-height:3rem;
  height:30px;

  cursor:pointer;
}

.data-controls .control-toggle:before {
  margin-right:5px;
  content:'';
  display:inline-block;
  font-family:'Font Awesome\ 5 Pro';
  font-weight:300;

  width:3rem;
  height:3rem;
  line-height:3rem;

  border:1px solid $colorAction;
  border-radius:50%;
  color:$colorAction;
}

.data-controls .toggle-show:before {
  content:"\f067";
}

.data-controls .toggle-hide:before {
  content:"\f068";
}